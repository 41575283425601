<template>
  <!-- eslint-disable -->
 
<div   style="text-align:left; ">
  <ModalFiltrosOrdenes ref="modalfiltros" :filtros="filtros" :ordenes="ordenes_calculated" />
  <ModalDescargarExcel ref="modaldescargar" />
  <ModalNuevaAgenda ref="modalnuevaagenda" />
  <ModalCopiarOrden ref="modalcopiarorden" />
  <ModalRecetas
        ref="modalRecetas"
        :ordenId="id_orden"
        @recetas-updated="handleRecetasUpdated"
      />
  
  <div  class="column-left">
    <SideNav />
  </div>

  
  <div class="column-right">
    <TopNav />
    <div class="main-container" style="">   
      
      <div  v-if="true" class="articulo-principal">
    
        <h2 style="display: inline-block;">Atenciones</h2> <button v-on:click="pantalla_completa()" :style="{'background-color':prestador.admin.assets.colors.secondary}" style="float:right" class="btn-ir-gordo"> {{ dynamicStyles.length == 0 ? 'Vista Completa' : 'Vista Normal' }} <b-icon icon="eye"></b-icon></button>    
        <div class="boxy">
          <a :href="link" ref="hiddenLink" style="display:none;">Hidden Link</a>
          <span style="font-style: italic;">*Arrastra con el cursor para ver todas las columnas. Puedes <b>clieckear una atención</b> para ver las opciones específicas de esa atención en particular.</span>
          <div class="container-fluid">
      <div
        v-if="popup"
        class="boxy"
        style="position:fixed; text-align:left; padding:3px;  z-index:9999; width: 200px;"
        :style="{top: Math.min(mouseY, window.height-360)+'px', left: mouseX-100+'px'}"
        @mouseover="popup = true;"
        @mouseleave="popup = false;"
      >
        <div>
          <!-- <span style="font-weight:700;">Opciones</span> -->

          <div
            class="menu-option"
            style="cursor:pointer;"
            @click="copyToClipboard()"
          >
          
            Copiar pacientes  
          </div>
          <div
            class="menu-option"
            style="cursor:pointer;"
            @click="copiar_link_pago()"
          >
          
            Copiar link de pago
          </div>
          <div
          class="menu-option"
            @click="enviar_confirmacion()"
          >
          
            Enviar confirmación
          </div>
          <div
          class="menu-option"
            @click="copiar_orden()"
          >
          
            Copiar orden
          </div>
<!--           <div
          class="menu-option"
            @click="marcar_pagado()"
          >
            Marcar como pagado
          </div> -->
          <div
          class="menu-option"
            @click="goto_modificar()"
          >
            Modificar Orden
          </div>
          <div
          class="menu-option"
            @click="goto_receta()"
          >
            Detalles de la orden
          </div>

<!--           <div
          class="menu-option"
            @click="goto_modificar_hora()"
          >
            Modificar Horario
          </div> -->
          <div

            class="cmt menu-option"

            @click="comentando = true"
          >
            Comentar
          </div>
          <div
            class="result menu-option"

            @click="subiendo_resultados.subiendo = true"
          >
            Subir resultados
          </div>
          <div
            class="result menu-option"
            @click="cambiar_profesional_abrir()"
          >
            Cambiar profesional o horario
          </div>
          <div
            class="result menu-option"
            @click="openRecetasModal"
          >
            Gestionar recetas
          </div>
          <div
          class="menu-option"
            @click="eliminarOrden()"
          >
            Eliminar orden
          </div>
        </div>
      </div>

      <div
        class=""
        style="position:relative; padding-top:5px;"
      >
      <div class="flex-top">
        <div class="flex-top-left">
          <button
          style="margin-right: 10px; margin-bottom: 10px;"
            class="menuCol btn-ir-gordo"
            :style="{'background-color':prestador.admin.assets.colors.primary}"
            @click="$refs.modalfiltros.show()"
          >
            Filtrar
            <b-icon icon="filter"></b-icon>
          </button>
          <button
          style="margin-right: 10px; margin-bottom: 10px;"
            class="menuCol btn-ir-gordo"
            :style="{'background-color':prestador.admin.assets.colors.primary}"
            @click="$refs.modaldescargar.show()"
          >
            Descargar
            <b-icon icon="download"></b-icon>
          </button>
          <button
          style="margin-right: 10px; margin-bottom: 10px;"
            class="menuCol btn-ir-gordo"
            :style="{'background-color':prestador.admin.assets.colors.primary}"
            @click="$refs.modalnuevaagenda.show()"
          >
            Nueva Orden
            <b-icon icon="plus-circle"></b-icon>
          </button>
        <div style=" display: inline-block; position: relative; ">
          <button
            class="menuCol btn-ir-gordo"
            :style="{'background-color':prestador.admin.assets.colors.primary}"
            @click="menuCol = !menuCol"
          >
            Ver Columnas
          </button>
          <div
          v-if="menuCol"
          class="menuCol"
          style="position:absolute; left:0px; top:47px; background-color:white; width:200px; border: 1px solid rgb(140, 140, 140); border-radius:5px; z-index:100;"
        >
          <div
           
            v-for="(c, key) in cols"
            :key="key"
            class="menuCol"
            style="cursor:pointer; width:100%; text-align:left; padding-left:5px;"
            @click=" setCol(c.var)"
          >
            {{ c.nombre }}
        
            <b-icon
              v-if="getVal(c.var) == 'true'"
              class="menuCol"
              style="color:green;"
              icon="check"
            />
            <b-icon
              v-else
              class="menuCol"
              style="color:red;"
              icon="x-circle"
            />
          </div>
        </div>

        </div>
        </div>
        <div class="flex-top-right">
          <div style="display: inline-block;  right:0px; top:10px;">
          Desde 
          <input
            id="x2"
            v-model="dt_dia"
            type="date"
          >
          </div>
          <div style="display: inline-block;">
           Hasta
          <input
            id="x2"
            v-model="dt_dia2"
            type="date"
          >
        </div>
        </div>
      </div>

    

<!--         <div
          style="position:absolute; left:0px; top: 10px; border:2px solid; border-radius:10px; padding:5px 10px; cursor:pointer; color:#1D6F42;"
          @click="descargarCSV()"
        >
          Descargar EXCEL <b-icon icon="cloud-download" />
        </div>
        <div
          style="position:absolute; left:390px; top: 10px; border:2px solid; border-radius:10px; padding:5px 10px; cursor:pointer; color:#1D6F42;"
          @click="descargarOrdenesMedicas()"f
        >
          Descargar Ordenes medicas <b-icon icon="cloud-download" />
        </div>
        <div
          style="position:absolute; left:180px; top: 10px; border:2px solid; border-radius:10px; padding:5px 10px; cursor:pointer; color:#1D6F42;"
          @click="descargarCSVPago()"
        >
          Descargar CSV Pagos <b-icon icon="cloud-download" />
        </div> -->
        <div  class="scroll-div" style="" v-if="!cargando_ordenes"> 
          <div class="table-container"        ref="tableContainer" 
       @mousedown="startDragging" 
       @mouseleave="stopDragging"
       :class="{ 'dragging': isDragging }">
            <table   style="">
          <thead >
          <tr style="">
            <th v-on:click="ordenar_por('id')"
              v-if="c1 == 'true'"
            >
              id
              <b-icon v-if="ordenar_por_selected == 'id'" class="chev-order" icon="chevron-down"></b-icon>
              <b-icon v-else-if="ordenar_por_selected == 'idr'" class="chev-order" icon="chevron-up"></b-icon>
            </th>
            <th v-on:click="ordenar_por('prestador')"
              v-if="c1 == 'true'"
           
            >
              Prestador Dueño
              <b-icon v-if="ordenar_por_selected == 'prestador'" class="chev-order" icon="chevron-down"></b-icon>
              <b-icon v-else-if="ordenar_por_selected == 'prestadorr'" class="chev-order" icon="chevron-up"></b-icon>
            </th>
            <th v-on:click="ordenar_por('prestador_tomador')"
              v-if="c1 == 'true'"
            >
              Prestador Tomador
              <b-icon v-if="ordenar_por_selected == 'prestador_tomador'" class="chev-order" icon="chevron-down"></b-icon>
              <b-icon v-else-if="ordenar_por_selected == 'prestador_tomadorr'" class="chev-order" icon="chevron-up"></b-icon>
            </th>
<!--             <th v-on:click="ordenar_por('markeplace')"
              v-if="c1 == 'true'"
              
            >
              De Marketplace
              <b-icon v-if="ordenar_por_selected == 'markeplace'" class="chev-order" icon="chevron-down"></b-icon>
              <b-icon v-else-if="ordenar_por_selected == 'markeplacer'" class="chev-order" icon="chevron-up"></b-icon>
            </th> -->
            <th
            v-on:click="ordenar_por('profesional_id')"
              v-if="c18 == 'true'"
            >
              Profesional
              <b-icon v-if="ordenar_por_selected == 'profesional_id'" class="chev-order" icon="chevron-down"></b-icon>
              <b-icon v-else-if="ordenar_por_selected == 'profesional_idr'" class="chev-order" icon="chevron-up"></b-icon>
            </th>
            <th v-on:click="ordenar_por('dia')"
              v-if="c2 == 'true'"
            >
              Fecha
              <b-icon v-if="ordenar_por_selected == 'dia'" class="chev-order" icon="chevron-down"></b-icon>
              <b-icon v-else-if="ordenar_por_selected == 'diar'" class="chev-order" icon="chevron-up"></b-icon>
            </th>
            <th v-on:click="ordenar_por('nombre')"
              v-if="c3 == 'true'"
            >
              Nombre
              <b-icon v-if="ordenar_por_selected == 'nombre'" class="chev-order" icon="chevron-down"></b-icon>
              <b-icon v-else-if="ordenar_por_selected == 'nombrer'" class="chev-order" icon="chevron-up"></b-icon>
            </th>
            <th
            v-on:click="ordenar_por('rut')"
              v-if="c4 == 'true'"
            >
              Rut
              <b-icon v-if="ordenar_por_selected == 'rut'" class="chev-order" icon="chevron-down"></b-icon>
              <b-icon v-else-if="ordenar_por_selected == 'rutr'" class="chev-order" icon="chevron-up"></b-icon>
            </th>
            <th v-on:click="ordenar_por('email')"
              v-if="c5 == 'true'"
            >
              Email
              <b-icon v-if="ordenar_por_selected == 'email'" class="chev-order" icon="chevron-down"></b-icon>
              <b-icon v-else-if="ordenar_por_selected == 'emailr'" class="chev-order" icon="chevron-up"></b-icon>
            </th>
            <th
              v-if="c24 == 'true'"
            >
              Fecha de nacimiento
              <b-icon v-if="ordenar_por_selected == 'email'" class="chev-order" icon="chevron-down"></b-icon>
              <b-icon v-else-if="ordenar_por_selected == 'emailr'" class="chev-order" icon="chevron-up"></b-icon>
            </th>
            <th
              v-if="c25 == 'true'"
            >
              Órdenes médicas
              <b-icon v-if="ordenar_por_selected == 'email'" class="chev-order" icon="chevron-down"></b-icon>
              <b-icon v-else-if="ordenar_por_selected == 'emailr'" class="chev-order" icon="chevron-up"></b-icon>
            </th>
            <th
            v-on:click="ordenar_por('telefono')"
              v-if="c6 == 'true'"
            >
              Teléfono
              <b-icon v-if="ordenar_por_selected == 'telefono'" class="chev-order" icon="chevron-down"></b-icon>
              <b-icon v-else-if="ordenar_por_selected == 'telefonor'" class="chev-order" icon="chevron-up"></b-icon>
            </th>
            <th
            v-on:click="ordenar_por('monto')"
              v-if="c7 == 'true'"
            >
              Monto
              <b-icon v-if="ordenar_por_selected == 'monto'" class="chev-order" icon="chevron-down"></b-icon>
              <b-icon v-else-if="ordenar_por_selected == 'montor'" class="chev-order" icon="chevron-up"></b-icon>
            </th>
            <th
            v-on:click="ordenar_por('descuento')"

              v-if="c8 == 'true'"
            >
              Descuento
              <b-icon v-if="ordenar_por_selected == 'descuento'" class="chev-order" icon="chevron-down"></b-icon>
              <b-icon v-else-if="ordenar_por_selected == 'descuentor'" class="chev-order" icon="chevron-up"></b-icon>
            </th>
            <th
            v-on:click="ordenar_por('monto_recargo')"
              v-if="c19 == 'true'"
            >
              Recargo
              <b-icon v-if="ordenar_por_selected == 'monto_recargo'" class="chev-order" icon="chevron-down"></b-icon>
              <b-icon v-else-if="ordenar_por_selected == 'monto_recargor'" class="chev-order" icon="chevron-up"></b-icon>
            </th>
            <th
            v-on:click="ordenar_por('desde')"
              v-if="c9 == 'true'"
            >
              Horario
              <b-icon v-if="ordenar_por_selected == 'desde'" class="chev-order" icon="chevron-down"></b-icon>
              <b-icon v-else-if="ordenar_por_selected == 'desder'" class="chev-order" icon="chevron-up"></b-icon>
            </th>
            <th style="min-width: 140px;"
            v-on:click="ordenar_por('estado')"
              v-if="c10 == 'true'"
            >
              Estado
              <b-icon v-if="ordenar_por_selected == 'estado'" class="chev-order" icon="chevron-down"></b-icon>
              <b-icon v-else-if="ordenar_por_selected == 'estador'" class="chev-order" icon="chevron-up"></b-icon>
            </th>
            <th style="min-width: 140px;"
            v-on:click="ordenar_por('contactado_whatsapp')"
              v-if="c22 == 'true'"
            >
              Autoconfirmación
              <b-icon v-if="ordenar_por_selected == 'contactado_whatsapp'" class="chev-order" icon="chevron-down"></b-icon>
              <b-icon v-else-if="ordenar_por_selected == 'contactado_whatsappr'" class="chev-order" icon="chevron-up"></b-icon>
            </th>
            <th
              v-if="c11 == 'true'"
            >
              Pagado

            </th>
            <th
              v-if="c23 == 'true'"
            >
              Monto Pagado

            </th>
            <th
              v-if="c20 == 'true'"
            >
              Resultados
            </th>
            <th
              v-if="c12 == 'true'"
            >
              Receta Médica
            </th>
            <th
              v-if="c13 == 'true'"
              v-on:click="ordenar_por('comuna_id')"
            >
              Comuna
              <b-icon v-if="ordenar_por_selected == 'comuna_id'" class="chev-order" icon="chevron-down"></b-icon>
              <b-icon v-else-if="ordenar_por_selected == 'comuna_idr'" class="chev-order" icon="chevron-up"></b-icon>
            </th>
            <th
              v-if="c14 == 'true'"
              v-on:click="ordenar_por('direccion')"
            >
              Dirección
              <b-icon v-if="ordenar_por_selected == 'direccion'" class="chev-order" icon="chevron-down"></b-icon>
              <b-icon v-else-if="ordenar_por_selected == 'direccionr'" class="chev-order" icon="chevron-up"></b-icon>
            </th>
            <th
              style="min-width: 200px;"
              v-if="c15 == 'true'"
            >
              Comentarios
            </th>
            <th
              style="min-width: 200px;"
              v-if="c16 == 'true'"
            >
              Historial
            </th>
            <th
              v-if="c17 == 'true'"
            >
              Exámenes
            </th>
            <th
              v-if="c21 == 'true'"
            >
              Atributos
            </th>
            <th :style="column.style_header != undefined ? column.style_header : {}" v-for="(column, index) in dynamicColumns" :key="'dynamic-' + index">{{ column.header }}</th>
          </tr>
        </thead>
          <tr
          
            v-for="(o, idx) in ordenes_calculated"
            v-if="mostrar_orden(o)"
            :key="idx"
            :style="{'background-color': o.urgente && o.estado == 1 ? '#FFDC00': 'white', 'color': o.urgente ? 'black': 'black', 'opacity':o.estado == 0 ? '0.3' : '1'}"
            style="vertical-align:top; cursor:pointer;"
            @click="mouseEnter($event, o.id, timeConvert(o.desde), timeConvert(o.hasta), o.profesional)"
          >
            <td
              v-if="c1 == 'true'"
              style="border-left: 1px solid black; border-bottom: 1px solid black;"
            >
              {{ o.id }}
            </td>
            <td
              v-if="c1 == 'true'"
              style="border-bottom: 1px solid black;"
            >
              <span
                :style="o.orden_propia ? 'color:blue;' : 'color:red;'"
              >
                {{ o.prestador }}
              </span>
            </td>
            <td
              v-if="c1 == 'true'"
              style="border-bottom: 1px solid black;"
            >
              <span
                :style="o.orden_tomada ? 'color:blue;' : 'color:red;'"
              >
                {{ o.prestador_tomador }}
              </span>
            </td>
<!--             <td
              v-if="c1 == 'true'"
              style="border-bottom: 1px solid black;"
            >
              <span
                :style="o.markeplace ? 'color:blue;' : 'color:red;'"
              >
                {{ o.markeplace ? 'SI' : 'NO' }}
              </span>
            </td> -->
            <td
              v-if="c18 == 'true'"
              style="border-bottom: 1px solid black;"
            >
              <span
                :style="`
                  color: ${o.profesional != null ? 'black' : 'red'};
                `"
              >
            
                {{
                  o.profesional != null ?
                    o.profesional.nombre + ' ' + o.profesional.apellido : 'SIN ASIGNAR'
                }}
                
              </span>
            </td>
            <td
              v-if="c2 == 'true'"
              style="border-bottom: 1px solid black;"
            >
              {{ new Date(o.fecha.a, o.fecha.m-1, o.fecha.d).toLocaleDateString("es-ES", { year: 'numeric', month: 'long', day: 'numeric' }) }}
              <b-icon :style="{'color':prestador.admin.assets.colors.secondary}" v-on:click="copyContent( new Date(o.fecha.a, o.fecha.m-1, o.fecha.d).toLocaleDateString('es-ES', { year: 'numeric', month: 'long', day: 'numeric' }))" class="copy-icon no-menu" icon="clipboard"></b-icon>
               
            
            </td>
            <td
              v-if="c3 == 'true'"
              style="border-bottom: 1px solid black;"
            >
            
              <div v-for="cl in o.clientes">
                -{{ cl.nombre }} {{ cl.apellido1 }} {{ cl.apellido2 }} 
                <b-icon :style="{'color':prestador.admin.assets.colors.secondary}" v-on:click="copyContent(cl.nombre + ' '+cl.apellido1)" class="copy-icon no-menu" icon="clipboard"></b-icon>
                ({{ aseguradoras.find(x=> x.id == cl.id_asegurador).nombre }})
              </div>
            </td>
            <td
              v-if="c4 == 'true'"
              style="border-bottom: 1px solid black;"
            >
            
              <div v-for="cl in o.clientes">
                {{ cl.rut }}
                <b-icon :style="{'color':prestador.admin.assets.colors.secondary}" v-on:click="copyContent(cl.rut)" class="copy-icon no-menu" icon="clipboard"></b-icon>
              </div>
            </td>
            <td
              v-if="c5 == 'true'"
              style="border-bottom: 1px solid black;"
            >
            
              <div v-for="cl in o.clientes">
                {{ cl.email }}
                <b-icon :style="{'color':prestador.admin.assets.colors.secondary}" v-on:click="copyContent(cl.email)" class="copy-icon no-menu" icon="clipboard"></b-icon>
              </div>
            </td>
            <td
              v-if="c24 == 'true'"
              style="border-bottom: 1px solid black;"
            >
              <div v-for="cl in o.clientes">
                {{ new Date(cl.fecha_nacimiento).toLocaleDateString() }}
                <b-icon :style="{'color':prestador.admin.assets.colors.secondary}" v-on:click="copyContent(new Date(cl.fecha_nacimiento).toLocaleDateString())" class="copy-icon no-menu" icon="clipboard"></b-icon>
              </div>
            </td>
            <td
              v-if="c25 == 'true'"
              style="border-bottom: 1px solid black;"
            >
              
              <template v-for="cl in o.clientes" v-if="o.recetas.find(z=>z.cliente_id == cl.id)" >
                Para<b> {{ cl.nombre }}</b>
                <div :style="{'color':prestador.admin.assets.colors.primary}" style="display: inline-block; cursor: pointer;"  v-on:click="fetchAndClickLink(rec.id)" v-for="rec, indx in o.recetas.filter(x=>x.cliente_id == cl.id)">
                  ({{ indx+1 }})
                </div>


              </template>
            </td>
            <td
              v-if="c6 == 'true'"
              style="border-bottom: 1px solid black;"
            >
              <div v-for="cl in o.clientes">
                {{ cl.telefono }}
                <b-icon :style="{'color':prestador.admin.assets.colors.secondary}" v-on:click="copyContent(cl.telefono)" class="copy-icon no-menu" icon="clipboard"></b-icon>
              </div>
            </td>
            <td
              v-if="c7 == 'true'"
              style="border-bottom: 1px solid black;"
            >
              ${{ Intl.NumberFormat('de-DE').format(o.monto) }}
            </td>
            <td
              v-if="c8 == 'true'"
              style="border-bottom: 1px solid black;"
            >
              ${{ Intl.NumberFormat('de-DE').format(o.descuento) }}
            </td>
            <td
              v-if="c19 == 'true'"
              style="border-bottom: 1px solid black;"
            >
              ${{ Intl.NumberFormat('de-DE').format(o.monto_recargo) }}
            </td>
            <td
              v-if="c9 == 'true'"
              style="border-bottom: 1px solid black;"
            >
              {{ timeConvert(o.desde) }}-{{ timeConvert(o.hasta) }}
            </td>
            <td
              v-if="c10 == 'true'"
              style="border-bottom: 1px solid black;"
            >
            <div class="cell-content">
              <select
                id=""
                v-model="o.estado"
                name=""
                style="padding:5px;"
                class="no-menu form-select form-select-lg mb-3"
                @change="cambiarEstado2(o.id, o.estado)"
              >
                <option
                  v-for="e, z in estados"
                  :value="z"
                >
                  {{ e }}
                </option>
              </select>
            </div>

            </td>
            <td style="text-align: center;" v-if="c22 == 'true'">
                <div :style="{'background-color':prestador.admin.assets.colors.secondary}" class="label-contact" v-if="o.contactado_whatsapp == 1"> Se le envió el primer correo <b-icon icon="card-text"></b-icon></div>
                <div :style="{'background-color':prestador.admin.assets.colors.secondary}" class="label-contact" v-else-if="o.contactado_whatsapp == 2"> Se le envió el segundo correo <b-icon icon="card-text"></b-icon> </div>
                <div :style="{'background-color':prestador.admin.assets.colors.primary}" class="label-contact" v-else-if="o.contactado_whatsapp == 3"> Se le envió un Whatsapp <b-icon icon="whatsapp"></b-icon></div>
                <div style="background-color: rgb(140, 140, 140);" class="label-contact" v-else> No se ha contactado <b-icon icon="x-circle"></b-icon></div>
            </td>
            <td
              v-if="c11 == 'true'"
              style="border-bottom: 1px solid black; text-align:center;"
            >
              <b-icon
                v-if="o.pagado == 1"
                style="color:green; height:50px; width:50px;"
                icon="check"
              /> <b-icon
                v-else
                style="color:red; height:40px; width:40px;"
                icon="x-circle"
              />
            </td>
            <td
              v-if="c23 == 'true'"
              style="border-bottom: 1px solid black; text-align:center;"
            >
            ${{ Intl.NumberFormat('de-DE').format(o.monto_pagado) }}
            </td>
            <td
              v-if="c20 == 'true'"
              style="border-bottom: 1px solid black; text-align:center;"
            >
              <b-icon
                v-if="o.clientes.reduce((x, y)=>{return x + y.resultados.length},0) > 0"
                style="color:green; height:50px; width:50px;"
                icon="check"
              /> <b-icon
                v-else
                style="color:red; height:40px; width:40px;"
                icon="x-circle"
              />
            </td>
            <td
              v-if="c12 == 'true'"
              style="border-bottom: 1px solid black; text-align:center;"
            >
              <b-icon
                v-if="!requierenReceta(o.examenes, o.clientes, o.recetas)"
                style="color:green; height:50px; width:50px;"
                icon="check"
              /> <b-icon
                v-else
                style="color:red; height:40px; width:40px;"
                icon="x-circle"
              />
            </td>
            <td
              v-if="c13 == 'true'"
              style="border-bottom: 1px solid black;"
            >
              {{ comunas.filter(x=>{return x.id == o.comuna_id})[0].Nombre }}
            </td>
            <td
              v-if="c14 == 'true'"
              style="border-bottom: 1px solid black;"
            >
              {{ o.direccion }} ({{ o.departamento == 1 ? 'Departamento '+o.n_depto : 'Casa' }})

              <b-icon :style="{'color':prestador.admin.assets.colors.secondary}" v-on:click="copyContent( o.direccion + ' ('+(o.departamento == 1 ? 'Departamento '+o.n_depto : 'Casa')+')')" class="copy-icon no-menu" icon="clipboard"></b-icon>

            </td>
            <td
              v-if="c15 == 'true'"
              style="border-bottom: 1px solid black;"
            >
              <div
               v-for="c, i in o.comentarios.slice().filter(x=>{return x.tipo == 2}).sort(compareDt)"
               v-if="(i < 1 || o.viendoComentarios)"
               >
                <span v-if="c.autor != null">
                  <b-icon
                  v-if="admin_datos.id == c.autor.id"
                  class="no-menu"
                  style="color:red"
                  icon="trash"
                  @click="eliminarComentario(c.id)"
                />
                </span>
 <b>{{ new Date(c.fecha.a, c.fecha.m-1, c.fecha.d, c.fecha.h, c.fecha.min).toLocaleDateString("es-ES", { year: 'numeric', month: 'long', day: 'numeric' }) }} por {{ c.autor != null ?  c.autor.nombre : 'Sin Autor' }}:</b> {{ c.texto }}
              </div>
              <button
                  v-if="o.comentarios.slice().filter(x=>{return x.tipo == 2}).length > 1"
                  style="margin: 0 auto; min-width:110px;"
                  :style="{'background-color':prestador.admin.assets.colors.secondary}"
                  class=" btn-ir-gordo no-menu"
                  @click="o.viendoComentarios = !o.viendoComentarios; $forceUpdate();"
                >
                {{ o.viendoComentarios ? 'Esconder': 'Mostrar más' }}
                </button>
            </td>
            <td
              v-if="c16 == 'true'"
              style="border-bottom: 1px solid black;"
            >
              <div
                v-for="c, i in o.comentarios.slice().filter(x=>{return x.tipo == 1}).sort(compareDt)"
                v-if="(i < 1 || o.viendoHistorial)"
              >
                <b>{{ new Date(c.fecha.a, c.fecha.m-1, c.fecha.d).toLocaleDateString("es-ES", { year: 'numeric', month: 'long', day: 'numeric' }) }}:</b> {{ c.texto }}
              </div><div style="width:100%; text-align:center; padding:10px 0px;">
                <button
                  v-if="o.comentarios.slice().filter(x=>{return x.tipo == 1}).length > 1"
                  style="margin: 0 auto; min-width:110px;"
                  :style="{'background-color':prestador.admin.assets.colors.secondary}"
                  class=" btn-ir-gordo no-menu"
                  @click="o.viendoHistorial = !o.viendoHistorial; $forceUpdate();"
                >
                  {{ o.viendoHistorial ? 'Esconder historial': 'Mostrar Historial' }}
                </button>
              </div>
            </td>
            <td
              v-if="c17 == 'true'"
              style="border-right: 1px solid black; border-bottom: 1px solid black;"
            >
        
              <div v-for="e in o.examenes">
                -{{ e.cantidad }} x {{ e.nombre }}  {{ e.codigo != null ? '('+e.codigo+')' : '' }}
              </div>
            </td>
            <td
              v-if="c21 == 'true'"
              style="border-right: 1px solid black; border-bottom: 1px solid black;"
            >
              <span
                v-if="o.atributos != undefined && Object.keys(o.atributos).length != 0"
              >
                <span
                  v-for="key_ in Object.keys(o.atributos)"  
                >
                  {{ key_ }}:{{ o.atributos[key_] }}
                  
                </span>

              </span>
              <span
                v-else
              >
                No tiene
              </span> 
            </td>

            <td v-for="(column, index) in dynamicColumns" :key="'dynamic-' + index">
            <div class="no-menu" v-if="column.type === 'text'">
              <!-- :value="o.atributos.hasOwnProperty(column.field) ? o.atributos[column.field] : column.default" -->
              <input class="no-menu" v-model="o.atributos[column.field]" type="text" 
              
              @input="debouncedUpdateField(idx, column.field, $event.target.value)">
            </div>
            <div class="no-menu cell-content" v-else-if="column.type === 'select'">
              <select class="no-menu" v-model="o.atributos[column.field]" @input="updateField(idx, column.field, $event.target.value)">
                <option v-for="option in column.options" :key="option" :value="option">{{ option }}</option>
              </select>
            </div>
          </td>

          </tr>
          <tr style="border-top: 1px solid black;" />
        </table>
          </div>

          </div>
          <div style="height: calc(100vh - 200px); display: flex; justify-content: center; align-items: center;" v-else>
            <Loader :height="100" :width="100" />

          </div>
          </div>
    </div>
          
        </div>

            <!-- modal de comentarios -->
    <div v-if="comentando">
      <div class="back-cover" />

      <div
        class="cmt" 
        style="position:fixed; border-radius:15px; padding:20px 30px 30px 30px; background:white; top:Calc(50% - 200px); height:400px; width:800px; max-width:90%; margin: 0px auto; left: 0; right: 0; opacity:1; z-index:99999;"
      >
        <div class="cmt">
          <h4
            class="cmt"
            style="margin-bottom:20px;"
          >
            Comenta la orden {{ id_orden }}
          </h4>
          <b-icon
            class="cmt"
            style="position:absolute; cursor:pointer; height:30px; width:30px; top:15px; right:15px;"
            icon="x-circle"
            @click="comentando = false;"
          />
          <div
            class="cmt"
            style="padding:0px 10px 0px 0px;"
          >
            <textarea
              id="w3review"
              v-model="comentario"
              name="w3review"
              class="cmt"
              style="resize: none; width:100%; height:250px;"
              rows="4"
              cols="50"
            />   
          </div>

          <div
            class="cmt"
            style="position:absolute; right:20px;"
          >
            ({{ comentario.length }}/500)
          </div>
          <div
            class="cmt"
            style="width:100%;"
          >
            <button
              class="btn-ir-gordo"
              :style="{'background-color':prestador.admin.assets.colors.primary}"
              style="margin: 10px;"
              @click="comentar()"
            >
              Enviar
            </button>
          </div>
        </div>
      </div>
    </div>


    <!-- modal subir resultados -->

    <div v-if="subiendo_resultados.subiendo">
      <div class="back-cover" />
      <div
        class="result" 
        style="position:fixed; border-radius:15px; padding:20px 30px 30px 30px; background:white; top:Calc(50% - 200px); height:400px; width:800px; max-width:90%; margin: 0px auto; left: 0; right: 0; opacity:1; z-index:99999"
      >
        <div class="result">
          <h4
            class="result"
            style="margin-bottom:20px;"
          >
            Subiendo resultados para la orden: {{ id_orden }}
          </h4>

          <div
            v-for="o in ordenes_calculated.find(z=>{ return z.id == id_orden}).clientes"
            :key="o.id"
            class="result"
          >
          
            {{ o.nombre }} {{ o.apellido1 }} {{ o.apellido2 }}
            <input
              :id="'file'+o.id"
              :ref="'file'+o.id"
              type="file"
              class="w-px h-px opacity-0 overflow-hidden absolute result"
              style="display:none;"
              accept=".pdf,.jpg,.jpeg,.png"
              @change="onChange(o.id)"
            >
            <div class="result">
              <label
                :for="'file'+o.id"
                class="block cursor-pointer result"
              >
                        
                <div
                  class="result"
                  style="width:100%;"
                >
                  <div
                    :for="'file'+o.id"
                    :style="{'background-color':prestador.admin.assets.colors.secondary}"
                    class="btn-ir-gordo result"
                  >Examinar <b-icon
                    icon="search"
                    class="result"
                    style="transform:translateY(3px);"
                  /></div>
                </div>                        
                        
              </label>
            </div>
            <div v-for="x in subiendo_resultados.resultados.filter(y=>{return y.cliente_id == o.id})">
              {{ x.resultado.name }} <b-icon
                class="result"
                icon="x-circle"
                style="color:red;"
                @click=""
              />
            </div>
          </div> 
          <b-icon
            class="result"
            style="position:absolute; cursor:pointer; height:30px; width:30px; top:15px; right:15px;"
            icon="x-circle"
            @click="limpiar_subiendo_resultados();"
          />
          <div
            class="result"
            style="padding:0px 10px 0px 0px;"
          />

    
          <div
            class="result"
            style="width:100%;"
          >
            <button
              class="btn-ir-gordo"
              style="margin: 10px 0px;"
              :style="{'background-color':prestador.admin.assets.colors.primary}"
              @click="subir_resultados()"
            >
              Subir Resultados <b-icon
                class="result"
                icon="reply"
                style="color:white;"
              />
            </button>
          </div>
        </div>
      </div>
    </div>


    <!-- modal cambiar profesional -->
    <div v-if="cambiar_profesional.cambiando">
      <div class="back-cover" />
      <div
        class="result" 
        style="position:fixed; border-radius:15px; padding:20px 30px 30px 30px; background:white; top:Calc(50% - 200px); height:500px; width:800px; max-width:90%; margin: 0px auto; left: 0; right: 0; opacity:1; z-index:99999;"
      >
        <div class="result">
          <h4
            class="result"
            style="margin-bottom:20px;"
          >
            Cambiando profesional para la orden: {{ id_orden }}
         
          </h4>

          <b-icon
            class="result" 
            style="position:absolute; cursor:pointer; height:30px; width:30px; top:15px; right:15px;"
            icon="x-circle"
            @click="cambiar_profesional_cerrar();"
          />

          <div v-if="cambiar_profesional.cargado == true">
            <div
              class="result"
            >
              <select
                v-model="cambiar_profesional.profesional"
                style="padding:5px;"
                class="no-menu form-select form-select-lg mb-3"
                @change="cambiar_profesional_seleccionar_profesional"
              >
                <option 
                  v-for="(profesional) in cambiar_profesional.profesionales"
                  :key="profesional.id"
                  :value="profesional"
                >
                  {{ profesional.nombre }} {{ profesional.apellido }}
                </option>
              </select>
              <div  v-if="cambiar_profesional.profesionales.length == 0">
                No se encontraron profesionales disponibles
              </div>
              <div v-if="cambiar_profesional.profesional">
                <input
                  id="x4"
                  style="border: 1px solid rgb(200,200,200); border-radius: 5px; padding: 5px; margin-bottom: 15px;"
                  v-model="cambiar_profesional.dt_dia"
                  @change="cambiar_profesional_ver_horarios_disponibles($event.target.value)"
                  type="date"
                >
              </div>

              
              <div v-if="cambiar_profesional.profesional && cambiar_profesional.horarios.length > 0">
                <select
                  v-model="cambiar_profesional.horario"
                  style="padding:5px;"
                  class="no-menu form-select form-select-lg mb-3"
                  @change="cambiar_profesional_seleccionar_horario"
                >
                  <option 
                    v-for="(horario) in cambiar_profesional.horarios.filter(h=>{return h.repeticion[new Date(cambiar_profesional.dt_dia).getDay()] == '1'})"
                    :key="horario.id"
                    :value="horario"
                  >
                    Horario {{ horario.id }} | {{ minutes_to_time(horario.inicio) }} - {{ minutes_to_time(horario.termino) }} | Prioridad {{ horario.prioridad }}
                  </option>
                </select>
              </div>
              <div v-else-if="cambiar_profesional.profesional && cambiar_profesional.horario.length == 0">
                No se encontraron horarios disponibles
              </div>
              <div v-if="cambiar_profesional.profesional && cambiar_profesional.horario && cambiar_profesional.horas.length > 0">
                <select
                  v-model="cambiar_profesional.hora"
                  style="padding:5px;"
                  class="no-menu form-select form-select-lg mb-3"
                >
                  <option 
                    v-for="(hora, key) in cambiar_profesional.horas"
                    :key="key"
                    :value="{ desde: hora.start_time, hasta: hora.end_time }"
                  >
                    {{ hora.start_time }} - {{ hora.end_time }}
                  </option>
                </select>
              </div>
              <div v-else-if="cambiar_profesional.profesional && cambiar_profesional.horario && cambiar_profesional.horas.length == 0 && !cambiar_profesional.forzado">
                No se encontraron horas disponibles
                <button v-on:click="cambiar_profesional.forzado = true; $forceUpdate();" :style="{'background-color':prestador.admin.assets.colors.primary}" class="btn-ir-gordo">
                  Forzar cambio
                </button>
              </div>
              <div v-else-if="cambiar_profesional.profesional && cambiar_profesional.horario && cambiar_profesional.horas.length == 0 && cambiar_profesional.forzado">
                <select
                  v-model="cambiar_profesional.hora"
                  style="padding:5px;"
                  class="no-menu form-select form-select-lg mb-3"
                >
                  <option 
                    v-for="(hora, key) in (cambiar_profesional.horario.termino - cambiar_profesional.horario.inicio)/cambiar_profesional.horario.intervalo"
                    :key="key"
                    :value="{
                       desde: timeConvert((hora - 1)*cambiar_profesional.horario.intervalo + cambiar_profesional.horario.inicio), 
                       hasta: timeConvert(hora*cambiar_profesional.horario.intervalo + cambiar_profesional.horario.inicio)
                        }"
                  >
                    {{ timeConvert((hora - 1)*cambiar_profesional.horario.intervalo + cambiar_profesional.horario.inicio) }}
                     - 
                    {{ timeConvert(hora*cambiar_profesional.horario.intervalo + cambiar_profesional.horario.inicio) }}
                  </option>
                </select>
<!--                 {{ cambiar_profesional.hora }}
                {{ cambiar_profesional.profesional }}
                {{ cambiar_profesional.horario }} -->
              </div>
            </div>
            <div v-if="cambiar_profesional.profesional && cambiar_profesional.horario && (cambiar_profesional.horas.length > 0 || cambiar_profesional.forzado) && cambiar_profesional.hora">
              <div>
                <table>
                  <tr>
                    <th> Apartado </th>
                    <th> Nuevo </th>
                    <th> Antiguo </th>
                  </tr>
                  <!-- <tr>
                    <td> <strong> Fecha </strong>  </td>
                    <td> {{ dt_dia }} </td>
                    <td> {{ dt_dia }} </td>
                  </tr> -->
                  <tr>
                    <td> <strong> Profesional </strong> </td>
                    <td> {{ cambiar_profesional.profesional.nombre }} {{ cambiar_profesional.profesional.apellido }} </td>
                    <td> {{ profesional }} </td>
                  </tr>
                  <tr>
                    <td> <strong> Hora inicio </strong> </td>
                    <td> {{ cambiar_profesional.hora.desde }} </td>
                    <td> {{ orden_desde }} </td>
                  </tr>
                  <tr>
                    <td> <strong> Hora fin </strong> </td>
                    <td> {{ cambiar_profesional.hora.hasta }} </td>
                    <td> {{ orden_hasta }} </td>
                  </tr>
                </table> 
              </div>
              <button
                class="btn btn-dark"
                style="margin: 10px;"
                @click="cambiar_orden_a_profesional"
              >
                Cambiar profesional <b-icon
                  class="result"
                  icon="reply"
                  style="color:white;"
                />
              </button>
            </div>
          </div>
          <div v-else>
            Buscando profesionales disponibles...
          </div>

          <div
            class="result"
            style="padding:0px 10px 0px 0px;"
          />
        </div>
      </div>
    </div>

      </div>
      <div v-else class="articulo-principal">
        <div style="height: calc(100vh - 140px); display: flex; justify-content: center; align-items: center;">


<Loader :height="100" :width="100" />


          </div>

        

      </div>
    </div>
  </div>



</div>
</template>

<script>
/* eslint-disable */
import axios from 'axios';
import {mapGetters, mapActions} from 'vuex';
import Fuse from 'fuse.js'
import SideNav from '@/components/SideNav.vue';
import Loader from '@/components/Loader.vue';
import TopNav from '@/components/TopNav.vue';
import ModalFiltrosOrdenes from '@/components/utils/ModalFiltrosOrdenes.vue';
import ModalDescargarExcel from '@/components/utils/ModalDescargarExcel.vue';
import ModalCopiarOrden from '@/components/utils/ModalCopiarOrden.vue';
import ModalNuevaAgenda from '@/components/utils/ModalNuevaAgenda.vue';
import ModalRecetas from "@/components/utils/ModalRecetas.vue"; 


export default {
  name: 'HelloWorld',
  props:['id'],
  components: {
    SideNav,
    Loader,
    TopNav,
    ModalFiltrosOrdenes,
    ModalNuevaAgenda,
    ModalCopiarOrden,
    ModalDescargarExcel,
    ModalRecetas,
  },
  data() {
    return {

      window: {
        width: 0,
        height: 0
      },
      link:'',
      isDragging: false,
      startX: 0,
      startY: 0,
      scrollLeft: 0,
      scrollTop: 0,

      ordenar_por_selected:'id',

      subiendo_resultados:{
        orden_id: 0,
        resultados:[],
        subiendo:false,
      },

      dynamicStyles: [],
      debounceTimers: {},
      
      cargando_ordenes:false,
      cambiar_profesional: {
        horarios_disponibles:[],
        dia_dt:'',
        cambiando: false,
        profesional: null,
        profesionales: [],
        horario: null,
        horarios: [],
        horas: [],
        hora: null,
        cargado: false,
        
      },

      dynamicColumns: [],

      email:'',
      nombre:'',
      apellido:'',
      rut:'',

      profesional: '',
      orden_desde: '',
      orden_hasta: '',

      dt_dia:'',
      dt_dia2:'',

      desde:{d:0, m:0, a:0},
      hasta:{d:0, m:0, a:0},

/*       filtros:{
        profesionales:[],
        prestador_dueño:[],
        prestador_tomador:[],
        comunas:[],
        config_id:[],
      }, */
      filtros:[
        {nombre:'Profesionales', excluidos:[]},
        {nombre:'Prestador Dueño', excluidos:[]},
        {nombre:'Prestador Tomador', excluidos:[]},
        {nombre:'Comunas', excluidos:[]},
        {nombre:'Estado', excluidos:[]},
        {nombre:'config_id', excluidos:[]},
      ],

      ordenes:[],
      ordenes_calculated:[],
      id_orden:0,

      popup: false,
      mouseX:0,
      mouseY:0,

      call_n:0,

      comentando:false,
      comentario:'',

      resultado:'',
      estados : ['Anulado', 'Citado',  'Confirmado', 'Atendido', 'No Atendido'],
      menuCol:false,
      cols:[
        {nombre:'Id', var:'c1'},        
        {nombre:'Fecha', var:'c2'},
        {nombre:'Nombre', var:'c3'},
        {nombre:'Rut', var:'c4'},
        {nombre:'Email', var:'c5'},
        {nombre:'Teléfono', var:'c6'},
        {nombre:'Monto', var:'c7'},
        {nombre:'Descuento', var:'c8'},
        {nombre:'Horario', var:'c9'},
        {nombre:'Estado', var:'c10'},
        {nombre:'Pagado', var:'c11'},
        {nombre:'Monto Pagado', var:'c23'},        
        {nombre:'Receta Médica', var:'c12'},
        {nombre:'Comuna', var:'c13'},
        {nombre:'Dirección', var:'c14'},
        {nombre:'Comentarios', var:'c15'},
        {nombre:'Historial', var:'c16'},
        {nombre:'Exámenes', var:'c17'},
        {nombre:'Profesional', var:'c18'},
        {nombre:'Recargo', var:'c19'},
        {nombre:'Resultados', var:'c20'},
        {nombre:'Atributos', var:'c21'},
        {nombre:'Autoconfirmación', var:'c22'},
        {nombre:'Fecha de nacimiento', var:'c24'},
        {nombre:'Ordenes médicas', var:'c25'},
      ],

      c1: localStorage.getItem('c1') || 'true',
      c2: localStorage.getItem('c2') || 'true',
      c3: localStorage.getItem('c3') || 'true',
      c4: localStorage.getItem('c4') || 'true',
      c5: localStorage.getItem('c5') || 'true',
      c6: localStorage.getItem('c6') || 'true',
      c7: localStorage.getItem('c7') || 'true',
      c8: localStorage.getItem('c8') || 'true',
      c9: localStorage.getItem('c9') || 'true',
      c10: localStorage.getItem('c10') || 'true',
      c11: localStorage.getItem('c11') || 'true',
      c12: localStorage.getItem('c12') || 'true',
      c13: localStorage.getItem('c13') || 'true',
      c14: localStorage.getItem('c14') || 'true',
      c15: localStorage.getItem('c15') || 'true',
      c16: localStorage.getItem('c16') || 'true',
      c17: localStorage.getItem('c17') || 'true',
      c18: localStorage.getItem('c18') || 'true',
      c19: localStorage.getItem('c19') || 'true',
      c20: localStorage.getItem('c20') || 'true',
      c21: localStorage.getItem('c21') || 'true',
      c22: localStorage.getItem('c22') || 'true',
      c23: localStorage.getItem('c23') || 'true',
      c24: localStorage.getItem('c24') || 'true',
      c25: localStorage.getItem('c25') || 'true',


    }
  },
  created () {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
    destroyed() {
    window.removeEventListener('resize', this.handleResize);

  },
  methods:{

...mapActions(['loginAdmin', 'getNotificaciones']),
handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    openRecetasModal() {
      const orden = this.ordenes.find(x => x.id === this.id_orden);
      if (orden) {
        this.$refs.modalRecetas.show(orden);
      } else {
        alert("Orden no encontrada.");
      }
    },
    async handleRecetasUpdated() {
      console.log("JOJOJOJOOO handleRecetasUpdated")
      // Refresh data or perform other actions
      await this.get_ordenes();
      this.openRecetasModal()
     /*  this.$forceUpdate() */
    },

async fetchAndClickLink(recetaId) {
      try {
        const response = await axios.post(window.hostname+'/api/admin/ver_receta', { receta_id: recetaId });
        this.link = response.data.link; // Adjust this based on your API response structure
        this.$nextTick(() => {
          this.$refs.hiddenLink.click();
        });
      } catch (error) {
        console.error('Error fetching the link:', error);
      }
    },
copyToClipboard() {
  let data =  this.ordenes.find(x=> x.id == this.id_orden)

  const rows = data.clientes.map(cliente => {
        return [
         //   '', // data.estado, // NÚMERO (Assumed to be the order ID)            
            data.id, // ORDEN (This can be whatever represents "ORDEN" in your data)
            new Date(data.fecha.a, data.fecha.m-1, data.fecha.d).toLocaleDateString(), // DÍA (formatted as DD/MM/YYYY)
            `${cliente.nombre} ${cliente.apellido1} ${cliente.apellido2}`.trim(), // PACIENTE
            new Date(cliente.fecha_nacimiento).toLocaleDateString(), // NACIMIENTO (No birthdate provided, leave blank or add if available)
            cliente.rut, // RUT
            cliente.email, // EMAIL
            cliente.telefono, // TELÉFONO
            data.examenes.map(examen => examen.nombre).join(', '), // EXÁMENES
            data.direccion + ' ('+(data.departamento == 1 ? 'Departamento '+data.n_depto : 'Casa')+')', // DIRECCIÓN
            this.comunas.find(x=> x.id == data.comuna_id).Nombre, // COMUNA (you may want to map ID to a name if needed)
            this.aseguradoras.find(x=>x.id == cliente.id_asegurador).nombre, // PREVISIÓN (Not provided)
            this.regiones.find(x=> x.id == this.comunas.find(x=> x.id == data.comuna_id).region).Nombre, // REGIÓN (Not provided)
            '', //data.monto, // COPAGO (If this is the right field, adjust if necessary)
            //'', //data.monto_pagado, // NETO
            //'', //data.atributos.ingreso, // INGRESOS
            '', // RESULTADO (Not provided, adjust if necessary)
            data.prestador, // RESULTADO (Not provided, adjust if necessary)
            new Date(data.desde * 60 * 1000).toISOString().substr(11, 5)+ ' - ' + new Date(data.hasta * 60 * 1000).toISOString().substr(11, 5), // HORA (converted from minutes)
            data.profesional.nombre +' '+ data.profesional.apellido, // TOMADOR
            //data.prestador_tomador, // TOMADOR
            //data.comentarios.map(com => com.texto).join(', ') // OBSERVACIÓN
        ].join('\t');
    }).join('\n');

    const el = document.createElement('textarea');
    el.value = rows;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    this.$notify({
      group: 'foo',
      title: '¡Éxito!',
      text: '¡Se copiaron los datos!',
      type:'success'
      });
},
copyContent(content) {
  navigator.clipboard.writeText(content).then(() => {
    this.$notify({
      group: 'foo',
      title: '¡Éxito!',
      text: '¡Se ha copiado!',
      type:'success'
      });
  }).catch(err => {
    console.error('Failed to copy: ', err);
  });
},
startDragging(e) {
      // Prevent default behavior to stop text selection
      /* e.preventDefault(); */
      
      this.isDragging = true;
      this.startX = e.pageX - this.$refs.tableContainer.offsetLeft;
      this.startY = e.pageY - this.$refs.tableContainer.offsetTop;
      this.scrollLeft = this.$refs.tableContainer.scrollLeft;
      this.scrollTop = this.$refs.tableContainer.scrollTop;
      
      document.addEventListener('mousemove', this.drag);
      document.addEventListener('mouseup', this.stopDragging);
    },
    drag(e) {
      if (!this.isDragging) return;
      /* e.preventDefault(); */
      
      const x = e.pageX - this.$refs.tableContainer.offsetLeft;
      const y = e.pageY - this.$refs.tableContainer.offsetTop;
      const walkX = (x - this.startX) * 1.5;
      const walkY = (y - this.startY) * 1.5;
      
      this.$refs.tableContainer.scrollLeft = this.scrollLeft - walkX;
      this.$refs.tableContainer.scrollTop = this.scrollTop - walkY;
    },
    stopDragging() {
      this.isDragging = false;
      document.removeEventListener('mousemove', this.drag);
      document.removeEventListener('mouseup', this.stopDragging);
    },
  
ordenar_por(attributeName, is_fecha=false) {
  function f_sort_attribute(low_to_hight,){
    return function(a, b){
    if (a[attributeName] < b[attributeName]) {
      return -low_to_hight;
    }
    if (a[attributeName] > b[attributeName]) {
      return low_to_hight;
    }
    return 0;
    }
  }

  if (this.ordenar_por_selected != attributeName || this.ordenar_por_selected == attributeName+'r')
  {
    this.ordenar_por_selected = attributeName;
    return this.ordenes_calculated.sort(f_sort_attribute(1));
  }
  this.ordenar_por_selected = attributeName+'r';
  return this.ordenes_calculated.sort(f_sort_attribute(-1));
},
updateField(rowIndex, field, value) {
      // Update the local table data
      //this.$set(this.tableData[rowIndex], field, value);
/*       this.ordenes_calculated[rowIndex].atributos[field] = value;
      this.$forceUpdate() */
      // Prepare the data to send to the backend
      const updatedData = {
        orden_id: this.ordenes_calculated[rowIndex].id, // Assuming each row has a unique ID
        field:field,
        value:value
      };


      // Make the API request to save the updated data
      axios.post(window.hostname+'/api/admin/update_dynamic_column',
      updatedData)
        .then(response => {
          console.log('Field updated successfully:', response.data);
        })
        .catch(error => {
          console.error('Error updating field:', error);
        });
    },
    debounce(func, delay) {
      let timerId;
      return function (...args) {
        const context = this;
        if (timerId) clearTimeout(timerId);
        timerId = setTimeout(() => {
          func.apply(context, args);
        }, delay);
      };
    },
    debouncedUpdateField(rowIndex, field, value) {
      // Using the debounce method directly in the component instance context
      const debouncedFunc = this.debounce(this.updateField, 500);
      debouncedFunc.call(this, rowIndex, field, value);
   
  },

pantalla_completa(){

  if(this.dynamicStyles.length == 0){

    this.addDynamicStyles(`
.articulo-principal{ max-width: 100vw !important;}
.column-right{padding-left:0px !important;}
.column-left{transform:translateX(-260px);}
.navbar-container{max-width:100vw !important;}

`)
  }
  else{
    this.removeDynamicStyles()

  }
},

addDynamicStyles(cssContent) {
      const style = document.createElement('style');
      style.type = 'text/css';
      style.appendChild(document.createTextNode(cssContent));
      // Add scoped attribute to limit the CSS to this component's root element
      style.setAttribute('scoped', '');
      this.$el.appendChild(style);
      this.dynamicStyles.push(style);
    },
    removeDynamicStyles() {
      this.dynamicStyles.forEach(style => {
        if (style.parentNode) {
          style.parentNode.removeChild(style);
        }
      });
      this.dynamicStyles = [];
    },
copiar_link_pago(){
    axios.post(window.hostname+'/api/admin/obtener_link_pago', 
    {orden_id:this.id_orden})
    .then(response => 
    {

      navigator.clipboard.writeText(response.data.link);
      this.popup = false;
      this.$notify({
      group: 'foo',
      title: '¡Éxito!',
      text: '¡Se ha copiado el link de pago!',
      type:'success'
      });
    })

    .catch(e=>{
      console.log(e.response)
      this.popup = false;
     
    })
},
onChange(cliente_id){

      
     if(["jpg", "png", "jpeg", "pdf"].includes(this.$refs['file'+cliente_id][0].files[0].name.split(".")[1].toLowerCase()))
        this.subiendo_resultados.resultados.push({cliente_id: cliente_id, resultado:this.$refs['file'+cliente_id][0].files[0]});

},
getVal(x){
  return this[x]
},
setCol(x){
  
  if(this[x] == 'true')
    this[x] = 'false'
  else
    this[x] = 'true'

  localStorage.setItem(x, this[x])

  

},
mostrar_orden(o){
  
  let filtro_profesional = this.filtros.find(x=>x.nombre=='Profesionales')
  if(o.profesional != undefined)
  {
    if(filtro_profesional.excluidos.includes(o.profesional.id))
    {
        return false;
    }
  }
    
  let filtro_prestador_tomador = this.filtros.find(x=>x.nombre=='Prestador Tomador')
  if(filtro_prestador_tomador.excluidos.includes(o.prestador_tomador))
  {
      return false;
  }
  let filtro_prestador_dueño = this.filtros.find(x=>x.nombre=='Prestador Dueño')
  if(filtro_prestador_dueño.excluidos.includes(o.prestador))
  {
      return false;
  }
  let filtro_comunas = this.filtros.find(x=>x.nombre=='Comunas')
  if(filtro_comunas.excluidos.includes(o.comuna_id))
  {
      return false;
  }
  let filtro_estados = this.filtros.find(x=>x.nombre=='Estado')
  if(filtro_estados.excluidos.includes(o.estado))
  {
      return false;
  }
  return true;
},
descargarCSV(){
  axios({
    url: window.hostname+'/api/admin/exportar_ordenes',
    method: 'POST',
    responseType: 'blob',
    data: {
      dia:this.desde.d, mes:this.desde.m, año:this.desde.a,
      dia2:this.hasta.d, mes2:this.hasta.m, año2:this.hasta.a
    }
  })
  .then( (response) =>  {
    console.log(response);
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'exportar.xlsx'); 
    document.body.appendChild(link);
    link.click();          
  })
  .catch(e=>{
    console.log(e);
  })

},
  descargarCSVPago(){
    axios({
      url: window.hostname+'/api/admin/exportar_excel_pago',
      method: 'POST',
      responseType: 'blob',
      data: {
        dia:this.desde.d, mes:this.desde.m, año:this.desde.a,
        dia2:this.hasta.d, mes2:this.hasta.m, año2:this.hasta.a
      }
    })
    .then(response =>{
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'exportar_pago.csv'); 
        document.body.appendChild(link);
        link.click();          
    })
    .catch( (e) =>{
      console.log(e)
    })

},

descargarOrdenesMedicas(){
    axios({
      url: window.hostname+'/api/admin/descargar_ordenes_medicas',
      method: 'POST',
      data: {
        day_from:this.desde.d, month_from:this.desde.m, year_from:this.desde.a,
        day_to:this.hasta.d, month_to:this.hasta.m, year_to:this.hasta.a
      },
      responseType: 'blob', // Important
    })
    .then(response =>{
      if(response.data.type != 'application/json')
      {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'ordenes_medicas.zip'); //or any other extension
        document.body.appendChild(link);
        link.click();
      }
      else{
        this.$notify({
      group: 'foo',
      title: '¡Error!',
      text: 'No hay ordenes médicas para el periodo seleccionado.',
      type:'error'
      });
      }

    })
    .catch( (e) =>{
      console.log(e)
    })

},
eliminarComentario(cid){
    axios.post(window.hostname+'/api/admin/eliminar_cometnario', 
    {comentario_id:cid})
    .then(response => 
    {
      this.get_ordenes()
      this.popup = false;
    })

    .catch(e=>{
      console.log(e.response)
     
    })

},
eliminarOrden(cid){
    axios.post(window.hostname+'/api/admin/borrar_orden', 
    {orden_id:this.id_orden})
    .then(response => 
    {
      this.get_ordenes()
      this.popup = false;
    })

    .catch(e=>{
      console.log(e.response)
      this.popup = false;
     
    })

},
requierenReceta(examenes, clientes, recetas){
  let requieren = []
  let examen_f = []
  for (var c of clientes){
    for(var e of examenes.filter(x=>{return x.cliente_id == c.id}))
    {
      examen_f = this.examenes.filter(x=>{return x.id == e.examen_id})
      if (examen_f.length > 0)
      {
        if (this.examenes.filter(x=>{return x.id == e.examen_id})[0].requiere_orden == 1)
        {
          requieren.push(c.id)
          break
        }
      }
 
    }
    //console.log(this.examenes.filter(x=>{return x.id == e.examen_id}))
  }
  for(var c of requieren)
  {
    if(!recetas.some(l=>{return l.cliente_id == c}))
      return true
  }
return false;
},
cambiarEstado(e){
    axios.post(window.hostname+'/api/admin/cambiarEstado', 
    {orden_id:this.id_orden, estado_id:e})
    .then(response => 
    {
      this.get_ordenes()
      this.popup = false;
    })

    .catch(e=>{
      console.log(e.response)
      if (e.response.data.message)
        this.resultado = e.response.data.message           
    })

},
  cambiarEstado2(o, e){
    let orden_original = this.ordenes.find(orden => orden.id === o);
    let orden_editando = this.ordenes_calculated.find(orden => orden.id === o);
    if(e == 0){
      if (!confirm("¿Seguro que deseas cambiar el estado a anulado?")) {
        orden_editando.estado = orden_original.estado;
/*         setTimeout(() => {
          orden_editando.estado = orden_original.estado;
          console.log("JOJOJOJOOOOOOOOOOOOOOOOOOOO", orden_editando.estado, orden_original.estado)
          this.$forceUpdate();
        }, 200) */
        this.getNotificaciones();

        return;
      }
    }

    axios.post(window.hostname+'/api/admin/cambiarEstado', 
    {orden_id:o, estado_id:e})
    .then(response => 
    {
      this.get_ordenes()
      this.popup = false;
      this.$notify({
      group: 'foo',
      title: '¡Éxito!',
      text: '¡Se ha cambiado el estado exitosamente!',
      type:'success'
      });
    })

    .catch(e=>{
      console.log(e.response)
      this.$notify({
      group: 'foo',
      title: '¡Error!',
      text: '¡Ha ocurrido un error!',
      type:'error'
      });
      if (e.response.data.message)
        this.resultado = e.response.data.message           
    })

},
comentar(){
    if(this.comentario.length <= 500)
    {
    axios.post(window.hostname+'/api/admin/hacer_comentario', 
    {orden_id:this.id_orden, texto:this.comentario})
    .then(response => 
    {
      this.get_ordenes()
      this.comentando = false;
      this.comentario = ''
    })

    .catch(e=>{
      console.log(e.response)
      if (e.response.data.message)
      this.$notify({
      group: 'foo',
      title: '¡Error!',
      text:  e.response.data.message,
      type:'error'
      });
        this.resultado = e.response.data.message           
    })
    }

},
limpiar_subiendo_resultados(){
  this.subiendo_resultados.resultados = []
  this.subiendo_resultados.subiendo = false;
},

minutes_to_time(min)
{
  let hora = Math.floor(min / 60);
  let minutos = min % 60;
  if (minutos < 10)
    minutos = `0${minutos}`;
  return `${hora}:${minutos}`;
},

cambiar_profesional_ver_horarios_disponibles(valor){
  
  this.cambiar_profesional.horarios_disponibles = this.cambiar_profesional.horarios.filter(horario=>{return horario.repeticion[new Date(valor).getDay()] == '1'}); 
  this.cambiar_profesional.horario = null;
  this.$forceUpdate();
},

async cambiar_profesional_abrir()
{
  await this.cambiar_profesional_obtener_profesionales_disponibles()
    .then(this.cambiar_profesional.cambiando = true)
},

cambiar_profesional_cerrar()
{
  this.cambiar_profesional = {
    cambiando: false,
    dt_dia:'',
    profesionales: [],
    profesional: null,
    horarios: [],
    horarios_disponibles: [],
    horario: null,
    horas: [],
    hora: null,
    cargado: false,
  }
},

async cambiar_profesional_obtener_profesionales_disponibles()
{
  await axios.get(window.hostname+'/api/admin/profesional')
  .then( (response) => {
    this.cambiar_profesional.profesionales = response.data
  })
  .then( () => { this.cambiar_profesional.cargado = true })
  .catch( e => { console.log(e.response) })
},

async cambiar_profesional_seleccionar_profesional()
{
  let orden = this.ordenes_calculated.find(z=>z.id == this.id_orden)

  this.cambiar_profesional.dt_dia = new Date(orden.fecha.a, orden.fecha.m-1, orden.fecha.d).toISOString().split('T')[0]
  this.cambiar_profesional.horario = null;
  this.cambiar_profesional.horarios = [];
  this.cambiar_profesional.horas = [];
  this.cambiar_profesional.hora = null;
  this.cambiar_profesional.forzando = false;
  await this.cambiar_profesional_obtener_ordenes_disponibles();
},

async cambiar_profesional_obtener_ordenes_disponibles()
{
  const profesional = this.cambiar_profesional.profesional.id
  await axios.get(window.hostname+`/api/admin/horario?profesional_id=${profesional}`)
  .then( (response) => {
    this.cambiar_profesional.horarios = response.data;
  })
  .catch( e => { console.log(e.response) } );
  this.cambiar_profesional_ver_horarios_disponibles(this.cambiar_profesional.dt_dia);
},

async cambiar_profesional_seleccionar_horario()
{
  this.cambiar_profesional.horas = [];
  this.cambiar_profesional.hora = null;
  this.cambiar_profesional.forzando = false;
  await this.cambiar_profesional_obtener_horas_disponibles();
},

async cambiar_profesional_obtener_horas_disponibles()
{  
  const date = this.cambiar_profesional.dt_dia;
  const horario_id = this.cambiar_profesional.horario.id
  await axios.get(window.hostname+`/api/admin/horario/bloque?date=${date}&selection=available&horario_id=${horario_id}`)
  .then( (response) => {
    this.cambiar_profesional.horas = response.data;
  })
  .catch( e => { console.log(e.response) } );
},

async cambiar_orden_a_profesional()
{
  const orden_id = this.id_orden;
  const profesional_id = this.cambiar_profesional.profesional.id;
  const horario_id = this.cambiar_profesional.horario.id;
  const dia = this.cambiar_profesional.dt_dia;
  let desde = this.cambiar_profesional.hora.desde;
  desde = parseInt(desde.split(':')[0]) * 60 + parseInt(desde.split(':')[1]);
  await axios.post(window.hostname+'/api/admin/schedule/change_order', {
    orden_id: orden_id,
    profesional_id: profesional_id,
    horario_id: horario_id,
    dia: dia,
    desde: desde,
  })
  .then( (response) => { alert(response.data.message) })
  .then( async () => { await this.get_ordenes() })
  .then( () => { this.cambiar_profesional_cerrar() })
  .catch( e => { console.log(e.response) })
},

subir_resultados(){
    let data = new FormData()
    data.append('orden_id', this.id_orden)

    let res = []
    for(var i = 0; i < this.subiendo_resultados.resultados.length; i++)
    {
      data.append(i, this.subiendo_resultados.resultados[i].resultado)
      res.push({cliente_id:this.subiendo_resultados.resultados[i].cliente_id, key:i})
    }
    const documento = new Blob([JSON.stringify({results:res})], {type:'application/json'});
    data.append('resultados', documento)
    if(this.subiendo_resultados.resultados.length > 0)
    {
      axios({
      method:'post', 
      url:window.hostname+'/api/admin/subir_resultado', 
      data: data,
      })
      .then(response => 
      {
        this.get_ordenes()
      this.$notify({
        group: 'foo',
        title: '¡Éxito!',
        text: '¡Se han subido los resultados exitosamente!',
        type:'success'
        });
        this.limpiar_subiendo_resultados()
      })

      .catch(e=>{
        console.log(e.response)
        if (e.response.data.message)
          this.resultado = e.response.data.message           
      })
    }

},
goto_resultados(){
  this.$router.push({ name: 'SubirResultado', params: { ordenId: this.id_orden } })
},
goto_modificar(){
  this.$router.push({ path: '/ModificarOrden/'+this.id_orden})
},
goto_receta(){
  this.$router.push({ path: '/VerReceta/'+this.id_orden})
},

goto_modificar_hora(){
  this.$router.push({ path: '/ModificarHorario/'+this.id_orden})
},
marcar_pagado(e){
    axios.post(window.hostname+'/api/admin/marcar_pagado', 
    {orden_id:this.id_orden})
    .then(response => 
    {
      this.get_ordenes()
      this.popup = false;
    })

    .catch(e=>{
      if (e.response.data.message)
        this.resultado = e.response.data.message           
    })

},
enviar_confirmacion(e){
    axios.post(window.hostname+'/api/admin/enviar_correo_confirmacion', 
    {orden_id:this.id_orden})
    .then(response => 
    {
      this.$notify({
      group: 'foo',
      title: '¡Éxito!',
      text: '¡Se ha enviado el correo de confirmación!',
      type:'success'
      });
      this.get_ordenes()
      this.popup = false;
    })

    .catch(e=>{
      if (e.response.data.message)
        this.resultado = e.response.data.message           
    })

},
copiar_orden(){
let orden = this.ordenes.find(x=>x.id == this.id_orden)
this.$refs.modalcopiarorden.show(orden)

},
 compareDt(a, b)
    {
      let da = new Date(a.fecha.a, a.fecha.m, a.fecha.d, a.fecha.h, a.fecha.min, a.fecha.seg)
      let db = new Date(b.fecha.a, b.fecha.m, b.fecha.d, b.fecha.h, b.fecha.min, b.fecha.seg)
      if(da > db){
        return -1
      }
      if (da < db){       
        return 1
      }
      return 0

    },
timeConvert(n) {
  var num = n;
  var hours = (num / 60);
  var rhours = Math.floor(hours);
  var minutes = (hours - rhours) * 60;
  var rminutes = Math.round(minutes);
  return (rhours < 10 ? '0':'')+rhours + ":"+ (rminutes < 10 ? '0':'') + rminutes + "";
},
mouseEnter(event, order_id, orden_desde, orden_hasta, profesional) {
    if (profesional == null) {
      profesional = 'No asignado'
    } else {
      profesional = `${profesional.nombre} ${profesional.apellido}`
    }
    
    if (!event.target.classList.contains('no-menu')){
      this.popup = true;
      this.id_orden = order_id;
      this.orden_desde = orden_desde;
      this.orden_hasta = orden_hasta;
      this.profesional = profesional;
      //this.$el.addEventListener('mousemove', this.mouseMove, false);
      this.mouseX = event.clientX;
      this.mouseY = event.clientY;
    }
},
mouseLeave(event) {
  if (!(event.clientX >= this.mouseX && event.clientY >= this.mouseY))
  {

    this.popup = false;
  }

},
close (e){
  /*Si clickeo (e.target) fuera de este componente (this.$el), se cierra*/
  if (!(e.target.classList.contains('dontClose')))
    this.showinFuncionesList = false

  if (!e.target.classList.contains('cmt') && this.comentando)
    this.comentando = false;

  if (!e.target.classList.contains('result') && this.subiendo_resultados.subiendo)
      this.limpiar_subiendo_resultados()

  if (!e.target.classList.contains('menuCol'))
    this.menuCol = false;
},
async get_ordenes() {
  this.call_n += 1;
  const currentCall = this.call_n;

  if (this.desde.a !== 0 && this.hasta.a !== 0) {
    this.cargando_ordenes = true;

    try {
      const response = await axios.post(`${window.hostname}/api/admin/get_ordenes_dia`, {
        dia: this.desde.d,
        mes: this.desde.m,
        año: this.desde.a,
        dia2: this.hasta.d,
        mes2: this.hasta.m,
        año2: this.hasta.a
      });

      this.cargando_ordenes = false;

      if (currentCall === this.call_n) {
        this.ordenes = response.data.ordenes;

        if (this.prestador.id !== 1) {
          this.ordenes = this.ordenes.filter(
            orden => orden.orden_propia === true || orden.orden_tomada === true
          );
        }

        // Define the compare function outside the loop for better performance
        const compare = (a, b) => {
          const da = new Date(a.fecha.a, a.fecha.m - 1, a.fecha.d, Math.floor(a.desde / 60));
          const db = new Date(b.fecha.a, b.fecha.m - 1, b.fecha.d, Math.floor(b.desde / 60));

          if (a.urgente && a.estado === 1 && !(b.urgente && b.estado === 1)) {
            return -1;
          }
          if (!(a.urgente && a.estado === 1) && (b.urgente && b.estado === 1)) {
            return 1;
          }
          return da - db;
        };

        this.ordenes_calculated = [];
        const hoy = new Date();

        this.ordenes.forEach(orden => {
          const ins = { ...orden };
          const dia = new Date(ins.fecha.a, ins.fecha.m - 1, ins.fecha.d);
          const diferenciaHoras = (dia.getTime() + 1000 * 60 * ins.desde - hoy.getTime()) / (1000 * 60 * 60);

          ins.urgente = diferenciaHoras < 48; // 2 * 24 hours
          ins.viendoHistorial = false;
          ins.viendoComentarios = false;
          ins.dia = dia;

          try {
            ins.email = ins.clientes[0].email;
          } catch (e) {
            console.error('Error fetching email:', e);
            ins.email = '';
          }

          this.ordenes_calculated.push(ins);
        });

        this.ordenes_calculated.sort(compare);
      }
    } catch (error) {
      console.error('Error fetching órdenes:', error);
      this.cargando_ordenes = false;
      // Optionally, you can rethrow the error if you want the caller to handle it
      // throw error;
    }
  }
}

},
computed:{
...mapGetters(['comunas', 'regiones', 'prestaciones', 'prestador', 'admin_datos', 'examenes', 'aseguradoras']),

},
watch:{
dt_dia(val){

    var datos = val.split('-')
    this.desde = {a:Number(datos[0]), m:Number(datos[1]), d:Number(datos[2])}
    this.get_ordenes()
  
},
dt_dia2(val){

    var datos = val.split('-')
    this.hasta = {a:Number(datos[0]), m:Number(datos[1]), d:Number(datos[2])}
    this.get_ordenes()
  
},

},
mounted() {

  this.getNotificaciones();

  if(this.prestador.admin.config.dynamicColumns != undefined){
    this.dynamicColumns = this.prestador.admin.config.dynamicColumns
  }


  this.$root.$on('Filtros', data => {
    let filtro = this.filtros.find(x=>x.nombre==data.nombre)
/*     console.log(data.valor)
    console.log("Parte:", filtro.excluidos) */
    if (filtro.excluidos.includes(data.valor))
    { 
      filtro.excluidos.splice(filtro.excluidos.indexOf(data.valor), 1)

    }
    else
    {
      filtro.excluidos.push(data.valor)
    }
/*     console.log("Termina:", filtro.excluidos) */
    this.$forceUpdate()
});

  const scrollDiv = document.querySelector('.scroll-div');

let isDragging = false;
let lastX;

scrollDiv.addEventListener('mousedown', (e) => {
    isDragging = true;
    lastX = e.clientX;
    scrollDiv.style.cursor = 'grabbing';
});

document.addEventListener('mousemove', (e) => {
    if (!isDragging) return;

    const deltaX = lastX - e.clientX;
    scrollDiv.scrollLeft += deltaX;

    lastX = e.clientX;
});

document.addEventListener('mouseup', () => {
    isDragging = false;
    scrollDiv.style.cursor = 'grab';
});

  this.$forceUpdate()
document.addEventListener('click', this.close)   
var hoy = new Date()
this.dt_dia = hoy.toISOString().split('T')[0]
hoy.setDate(hoy.getDate());
this.dt_dia2 = hoy.toISOString().split('T')[0]
},
beforeUnmount () {
  this.removeDynamicStyles()
document.removeEventListener('click',this.close)
}

}
</script>


<style scoped>
.menu-option:hover{
  background-color: rgb(245,245,245);

}
select {
  width: 100%;
  box-sizing: border-box;
}
.cell-content {
  position: relative;
  min-height: 20px; /* Adjust as needed */
}
.menu-option{
  padding: 4px;
  cursor: pointer;
}
.chev-order{
  position: absolute; transform: translate(1px, 3px);
}
.label-contact{
  border-radius: 4px;
  display: inline-block !important;
  width: auto;
  margin: 0 auto;
  text-align: center;
  background-color: red;
  padding: 2px 5px;
  color:#fff;

}
.flex-top{
  display: flex;
  width: 100%;

}
.flex-top-right{
  text-align: right;

  flex-grow: 1;
}
.flex-top-left{

 
}
.back-cover{
  position:fixed;

  left:0px;
  height:100vh; 
  width:100vw; 
  top:0px; 
  z-index:99999; 
  background-color: rgba(0,0,0,0.1);
  backdrop-filter: blur(2px);
}
.scroll-div {


    cursor: grab !important;
    overflow-x: scroll;
    overflow-y: hidden;
    position: relative;
   
}
.table-container {
  width: 100%;
  height: Calc(100vh - 100px);
  overflow: auto;
  position: relative;
  cursor: grab;
}

.table-container.dragging {
  cursor: grabbing;
  user-select: none;
}

table {
  min-width: 100%;
  border-collapse: collapse;
  table-layout: auto; /* Changed to auto to allow flexible column widths */
}

thead {
  position: sticky;
  top: 0;
  background-color: #f8f8f8;

  z-index: 1;
}

th, td {
  border: 1px solid #ddd;
  padding: 12px; /* Increased padding for larger cells */
  text-align: left;
  word-wrap: break-word;
  min-width: 150px; /* Minimum width for columns */
  max-width: 300px; /* Maximum width for columns */
}

th {
  background-color: #f2f2f2;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 700;
  text-overflow: ellipsis;
}
/* table {
    border-collapse: collapse;
    width: 100%;
    margin-top: 20px;
}

td {
    border: 1px solid black;
    padding: 8px 12px;
}
th {

 
}

thead th {
 
  padding: 10px;
  top: 0px; 
  color: #000;
  border: none;
  font-weight: 700;

    z-index: 1; 
}

td{
  text-align: left;
} */
.articulo-principal{
 min-height: Calc(100vh - 120px);
  max-width:1390px;



  
  margin: 0 auto;




}
.boxy{
  background-color: white;
  padding: 20px;
  box-shadow: 0 0.125rem 0.25rem rgba(165, 163, 174, 0.3);
  border-radius: 8px;
}

.column-right{
  background-color: rgb(242, 247, 251);

  padding-top:0px;
  padding-left:275px;
  width:100%;

  height: 100%;


  

}
.main-container{

  padding: 10px;
  height: 100%;
  margin-top:10px;

}
@media (max-width: 800px) {
 .column-right{

  padding-left:0px;


}
}


















</style>

