<template>
  <!-- eslint-disable -->
  <div style="text-align:left; background-color: rgb(242, 247, 251);">
    <div class="column-left">
      <SideNav />
    </div>

    <div class="column-right">
      <TopNav />
      <div class="main-container">
        <div v-if="loaded" class="articulo-principal">
          <h2>Modificar horario id {{ $route.params.id }}</h2>
          <div class="boxy">
            <!-- Sección de características del horario -->
            <div style="display: flex;">
              <div style="display:inline-block; width:40%;">
                <div style="margin-bottom:20px;">
                  Intervalo
                  <div class="info-icon-container">
                    <i :style="{'color': prestador.admin.assets.colors.primary}" class="info-icon action-icon tf-icons ti ti-info-circle-filled"></i>
                    <div :style="{'background-color': prestador.admin.assets.colors.primary}" class="info-icon-container-popup">
                      Intervalos de tiempo que durará las atenciones, y que será mostrado en la agenda.
                    </div>
                  </div>
                  <select v-model="periodo">
                    <option v-for="o in periodos_posibles" :value="o">
                      {{ o }} minutos
                    </option>
                  </select>
                </div>
                <table style="margin: 0; margin-bottom:20px">
                  <tr>
                    <td style="vertical-align:middle; padding-right:20px;">
                      <div>
                        Inicio
                        <div class="info-icon-container">
                          <i :style="{'color': prestador.admin.assets.colors.primary}" class="info-icon action-icon tf-icons ti ti-info-circle-filled"></i>
                          <div :style="{'background-color': prestador.admin.assets.colors.primary}" class="info-icon-container-popup">
                            Inicio de la agenda, primera hora que se mostrará.
                          </div>
                        </div>
                      </div>
                      <select v-model="agregando_horario.inicio">
                        <option v-for="v in Math.round(1440/5)" :value="(v-1)*5">
                          {{ timeConvert((v-1)*5) }}
                        </option>
                      </select>
                    </td>
                    <td style="vertical-align:middle; padding-right:20px;">
                      <div>
                        Termino
                        <div class="info-icon-container">
                          <i :style="{'color': prestador.admin.assets.colors.primary}" class="info-icon action-icon tf-icons ti ti-info-circle-filled"></i>
                          <div :style="{'background-color': prestador.admin.assets.colors.primary}" class="info-icon-container-popup">
                            Término de la agenda, se mostrarán horas espaciadas por el intervalo seleccionado.
                          </div>
                        </div>
                      </div>
                      <select v-model="agregando_horario.termino">
                        <option v-for="v in Math.round((1440 - agregando_horario.inicio) / periodo)" :value="(v)*periodo + agregando_horario.inicio">
                          {{ timeConvert((v)*periodo + agregando_horario.inicio) }}
                        </option>
                      </select>
                    </td>
                    <td style="vertical-align:middle;">
                      <table>
                        <tr>
                          <td v-for="x in dias" style="text-align:center; width:30px;">
                            {{ x }}
                          </td>
                          <td>
                            <div class="info-icon-container">
                              <i :style="{'color': prestador.admin.assets.colors.primary}" class="info-icon action-icon tf-icons ti ti-info-circle-filled"></i>
                              <div :style="{'background-color': prestador.admin.assets.colors.primary}" class="info-icon-container-popup">
                                Días para los cuales estará habilitado el horario.
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td v-for="x in agregando_horario.repeticion" style="text-align:center;">
                            <input v-model="x.value" type="checkbox" :true-value="1" :false-value="0">
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
                <div style="margin-bottom:20px">
                  Anticipación necesaria (en horas)
                  <div class="info-icon-container">
                    <i :style="{'color': prestador.admin.assets.colors.primary}" class="info-icon action-icon tf-icons ti ti-info-circle-filled"></i>
                    <div :style="{'background-color': prestador.admin.assets.colors.primary}" class="info-icon-container-popup">
                      Una persona puede agendar una cita sólo si hay más de ésta cantidad de horas entre ahora y la cita.
                    </div>
                  </div>
                  <select v-model="anticipacion">
                    <option v-for="v in 25" :value="(v-1)*60">
                      {{ timeConvert((v-1)*60) }}
                    </option>
                  </select>
                </div>
                <div style="margin-bottom:20px">
                  Anticipación mínima (en horas)
                  <div class="info-icon-container">
                    <i :style="{'color': prestador.admin.assets.colors.primary}" class="info-icon action-icon tf-icons ti ti-info-circle-filled"></i>
                    <div :style="{'background-color': prestador.admin.assets.colors.primary}" class="info-icon-container-popup">
                      La mínima cantidad de horas entre la fecha actual y la cita.
                    </div>
                  </div>
                  <select v-model="anticipacion_minima">
                    <option v-for="v in 25" :value="(v-1)*60">
                      {{ timeConvert((v-1)*60) }}
                    </option>
                  </select>
                </div>
                <div style="margin-bottom:20px">
                  Anticipación autoconfirmación (en horas)
                  <div class="info-icon-container">
                    <i :style="{'color': prestador.admin.assets.colors.primary}" class="info-icon action-icon tf-icons ti ti-info-circle-filled"></i>
                    <div :style="{'background-color': prestador.admin.assets.colors.primary}" class="info-icon-container-popup">
                      Si el paciente agenda con una anticipación igual o menor a esta, la hora se confirma automáticamente.
                    </div>
                  </div>
                  <select v-model="anticipacion_autoconfirmacion">
                    <option v-for="v in 25" :value="v-1">
                      {{ timeConvert(((v-1)*60)) }}
                    </option>
                  </select>
                </div>
                <div style="margin-bottom:20px">
                  Solo se puede agendar hasta (días antes y hora):
                  <select v-model="anticipacion_fija_minima_dias">
                    <option v-for="day in [0,1,2,3]" :value="day">
                      {{ day }} Días antes
                    </option>
                  </select>
                  <select v-model="anticipacion_fija_minima_hora">
                    <option v-for="v in 25" :value="v-1">
                      A las {{ timeConvert(((v-1)*60)) }}
                    </option>
                  </select>
                </div>
                <div style="margin-bottom:20px">
                  Solo se puede agendar desde (días antes y hora):
                  <select v-model="disponible_desde_dias">
                    <option v-for="day in [0,1,2,3]" :value="day">
                      {{ day }} Días antes
                    </option>
                  </select>
                  <select v-model="disponible_desde_hora">
                    <option v-for="v in 25" :value="v-1">
                      A las {{ timeConvert(((v-1)*60)) }}
                    </option>
                  </select>
                </div>
                <div style="margin-bottom:20px">
                  Prioridad
                  <div class="info-icon-container">
                    <i :style="{'color': prestador.admin.assets.colors.primary}" class="info-icon action-icon tf-icons ti ti-info-circle-filled"></i>
                    <div :style="{'background-color': prestador.admin.assets.colors.primary}" class="info-icon-container-popup">
                      Horarios con mayor prioridad se mostrarán primero.
                    </div>
                  </div>
                  <select v-model="prioridad">
                    <option v-for="v in prioridades" :value="v">
                      {{ v }}
                    </option>
                  </select>
                </div>
                <div style="margin-bottom:20px">
                  Recargo (%)
                  <div class="info-icon-container">
                    <i :style="{'color': prestador.admin.assets.colors.primary}" class="info-icon action-icon tf-icons ti ti-info-circle-filled"></i>
                    <div :style="{'background-color': prestador.admin.assets.colors.primary}" class="info-icon-container-popup">
                      Se suma un % del total de la orden si se toma este horario.
                    </div>
                  </div>
                  <select v-model="recargo_porcentaje">
                    <option v-for="v in recargos" :value="v">
                      {{ v }}
                    </option>
                  </select>
                </div>
                <div style="margin-bottom:20px">
                  Recargo (monto)
                  <div class="info-icon-container">
                    <i :style="{'color': prestador.admin.assets.colors.primary}" class="info-icon action-icon tf-icons ti ti-info-circle-filled"></i>
                    <div :style="{'background-color': prestador.admin.assets.colors.primary}" class="info-icon-container-popup">
                      Se suma este monto si se toma el horario.
                    </div>
                  </div>
                  <input v-model="recargo_suma">
                </div>
                <div style="margin-bottom:20px">
                  Color:
                  <div style="display:inline-block; transform:translateY(8px);">
                    <InputColorPicker v-model="color" />
                  </div>
                  {{ color }}
                </div>
                <div style="display:inline-block; width:40%; vertical-align:top; margin-bottom:20px">
                  Genera bloqueo
                  <div class="info-icon-container">
                    <i :style="{'color': prestador.admin.assets.colors.primary}" class="info-icon action-icon tf-icons ti ti-info-circle-filled"></i>
                    <div :style="{'background-color': prestador.admin.assets.colors.primary}" class="info-icon-container-popup">
                      Al activar esta opción, tomar una hora en este horario bloqueará completamente el resto.
                    </div>
                  </div>
                  <input v-model="genera_bloqueo" type="checkbox">
                </div>
                <div style="width:40%; vertical-align:top; margin-bottom:20px">
                  Fecha inicio
                  <div class="info-icon-container">
                    <i :style="{'color': prestador.admin.assets.colors.primary}" class="info-icon action-icon tf-icons ti ti-info-circle-filled"></i>
                    <div :style="{'background-color': prestador.admin.assets.colors.primary}" class="info-icon-container-popup">
                      Fecha a partir de la cual el horario entra en vigencia.
                    </div>
                  </div>
                  <input v-model="fecha_inicio_checkbox" type="checkbox" style="display: inline-block;">
                  <input v-if="fecha_inicio_checkbox" style="display: inline-block;" v-model="fecha_inicio_dt" type="date">
                </div>
                <div style="width:40%; vertical-align:top; margin-bottom:20px">
                  Fecha término
                  <div class="info-icon-container">
                    <i :style="{'color': prestador.admin.assets.colors.primary}" class="info-icon action-icon tf-icons ti ti-info-circle-filled"></i>
                    <div :style="{'background-color': prestador.admin.assets.colors.primary}" class="info-icon-container-popup">
                      Indica hasta qué fecha el horario es válido.
                    </div>
                  </div>
                  <input v-model="fecha_termino_checkbox" type="checkbox" style="display: inline-block;">
                  <input v-if="fecha_termino_checkbox" style="display: inline-block;" v-model="fecha_termino_dt" type="date">
                </div>

                <!-- NUEVA SECCIÓN: Fechas específicas -->
                <div style="margin-bottom:20px">
                  <label>
                    <input type="checkbox" v-model="solo_fechas_especificas">
                    Habilitar fechas específicas para este horario
                  </label>
                </div>
                <div v-if="solo_fechas_especificas" style="position: relative; margin-bottom:20px;">
                  <MultiDatePicker
                    v-model="chosenDates"
                    ref="datePicker"
                    :x="calendarX"
                    :y="calendarY"
                  />
                  <button
                    :style="{'background-color': prestador.admin.assets.colors.primary}"
                    class="btn btn-success"
                    @click="openCalendar($event)"
                  >
                    Seleccionar Fechas
                  </button>
                  <div style="margin-top:10px;">
                    <strong>Fechas seleccionadas:</strong>
                    <div class="selected-dates">
                      <span v-for="(date, index) in chosenDates" :key="index" class="date-badge">
                        {{ formatDate(date) }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Sección de Comunas y Categorías -->
              <div style="display:inline-block; width:30%; vertical-align:top;">
                Comunas
                <div class="info-icon-container">
                  <i :style="{'color': prestador.admin.assets.colors.primary}" class="info-icon action-icon tf-icons ti ti-info-circle-filled"></i>
                  <div :style="{'background-color': prestador.admin.assets.colors.primary}" class="info-icon-container-popup">
                    Para cuáles comunas se mostrará este horario.
                  </div>
                </div>
                <div>
                  <input v-model="comunas_buscadas" type="text">
                  <div style="margin:0; position:relative; text-align:center; width:200px;">
                    <div v-if="comunas_buscadas != ''" style="background:white; position:absolute; left:1px; border: 1px solid black; margin: 0; width:202px;">
                      <div v-for="r in resultado_fuse_comuna.slice(0,10)" style="cursor:pointer;">
                        <span @click="agregar_comunas(r.item.id)">
                          {{ r.item.Nombre }}
                          <b-icon v-if="comunas_selected.includes(r.item.id)" icon="check"/>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-for="c in comunas_selected">
                  - {{ comunas.filter(x => x.id == c)[0].Nombre }}
                </div>
              </div>

              <div style="display:inline-block; width:30%; vertical-align:top;">
                Categorías
                <div class="info-icon-container">
                  <i :style="{'color': prestador.admin.assets.colors.primary}" class="info-icon action-icon tf-icons ti ti-info-circle-filled"></i>
                  <div :style="{'background-color': prestador.admin.assets.colors.primary}" class="info-icon-container-popup">
                    Este horario será visible para los pacientes con servicios asociados a las siguientes categorías.
                  </div>
                </div>
                <table style="width:100%; margin: 0px 0px;">
                  <tr style="background:black; color:white;">
                    <td>Id</td>
                    <td>Nombre</td>
                    <td>Habilitado</td>
                  </tr>
                  <tr v-if="categorias_usadas.includes(c.id)" v-for="c in editando_categorias">
                    <td style="padding:5px;">{{ c.id }}</td>
                    <td style="padding:5px;">{{ c.nombre }}</td>
                    <td style="text-align:center; padding:5px;">
                      <input v-model="c.puede" type="checkbox">
                    </td>
                  </tr>
                </table>
              </div>
            </div>

            <!-- Botones de guardar/eliminar -->
            <div style="text-align:center;">
              <button
                :style="{'background-color': prestador.admin.assets.colors.primary}"
                class="btn btn-success"
                style="margin: 10px auto;"
                @click="agregar_horario(profesional.id, agregando_horario.inicio, agregando_horario.termino, agregando_horario.repeticion)"
              >
                Guardar Horario
              </button>
              <div style="display: inline-block; width: 20px;"></div>
              <button
                class="btn btn-danger"
                style="margin: 10px auto;"
                @click="eliminar_horario($route.params.id)"
              >
                <b-icon icon="trash" style="margin-right:6px;"/>
                Eliminar horario
              </button>
            </div>
          </div>

          <!-- Sección de atención a prestadores -->
          <div style="margin-top:20px;" class="boxy">
            <br>
            <h4 style="margin-bottom:20px; text-align:left;">
              Atención a prestadores <b-icon icon="plus" />
            </h4>
            <div style="text-align:left;">
              <div style="padding:20px; border-radius:15px;">
                <div style="display:inline-block; width:40%; vertical-align:top;">
                  Prestadores
                  <table style="width:100%; margin: 0px 0px;">
                    <tr style="background:black; color:white;">
                      <td>Id</td>
                      <td>Nombre</td>
                      <td>Habilitado</td>
                    </tr>
                    <tr v-for="prestador in prestadores">
                      <td style="padding:5px;">{{ prestador.id }}</td>
                      <td style="padding:5px;">{{ prestador.nombre }}</td>
                      <td style="text-align:center; padding:5px;">
                        <input v-model="prestador.activo" type="checkbox">
                      </td>
                    </tr>
                  </table>
                </div>
                <div style="text-align:center;">
                  <button
                    :style="{'background-color': prestador.admin.assets.colors.primary}"
                    class="btn btn-success"
                    style="margin: 10px auto;"
                    @click="post_atencion_horario_prestador()"
                  >
                    <b-icon icon="plus-circle" style="margin-right:6px;"/>
                    Guardar cambios de atención a prestadores
                  </button>
                </div>
              </div>
            </div>
            <br>
          </div>
        </div>

        <!-- Loader mientras se carga -->
        <div v-else class="articulo-principal">
          <div style="height: calc(100vh - 140px); display: flex; justify-content: center; align-items: center;">
            <Loader :height="100" :width="100" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import axios from 'axios';
import Fuse from 'fuse.js';
import { mapGetters, mapActions } from 'vuex';
import SideNav from '@/components/SideNav.vue';
import InputColorPicker from 'vue-native-color-picker';
import Loader from '@/components/Loader.vue';
import TopNav from '@/components/TopNav.vue';
import MultiDatePicker from "./MultiDatePicker.vue";

export default {
  name: 'HelloWorld',
  components: {
    MultiDatePicker,
    InputColorPicker,
    TopNav,
    SideNav,
    Loader,
  },
  props: ['id'],
  data() {
    return {
      profesional: {},
      agregando_horario: {
        inicio: -1,
        termino: -1,
        repeticion: [
          { value: 0 },
          { value: 0 },
          { value: 0 },
          { value: 0 },
          { value: 0 },
          { value: 0 },
          { value: 0 }
        ]
      },
      dias: ['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Dom'],
      dias2: ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sabado', 'Domingo'],
      periodo: 60,
      periodos_posibles: [15, 30, 45, 60, 90, 120, 180, 240, 300, 360],
      loaded: false,
      comunas_selected: [],
      resultado_fuse_comuna: [],
      comunas_buscadas: '',
      color: '#000000',
      anticipacion: 0,
      anticipacion_minima: 0,
      anticipacion_autoconfirmacion: 0,
      anticipacion_fija_minima_dias: 0,
      anticipacion_fija_minima_hora: 0,
      disponible_desde_dias: 0,
      disponible_desde_hora: 0,
      nombre: '',
      editando_examenes: [],
      editando_categorias: [],
      genera_bloqueo: false,

      fecha_inicio_checkbox: false,
      fecha_termino_checkbox: false,
      fecha_inicio_dt: '',
      fecha_termino_dt: '',

      // Variables para fechas específicas
      solo_fechas_especificas: false,
      chosenDates: [],        // Array de strings "YYYY-MM-DD"
      calendarVisible: false,
      calendarX: 120,
      calendarY: -100,

      prioridades: [1, 2, 3, 4, 5],
      prioridad: 1,
      recargo_porcentaje: 0,
      recargo_suma: 0,
      recargos: [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100],
      prestadores_activos: [],
      prestadores: [],
    }
  },
  created() {
    this.initialize_(this.$route.params.id);
  },
  methods: {
    ...mapActions(['initialize']),
    formatDate(dateStr) {
    if (!dateStr) return '';
    const parts = dateStr.split('-');
    if (parts.length !== 3) return dateStr;
    // Retorna en el formato dd-mm-yyyy
    return `${parts[2]}-${parts[1]}-${parts[0]}`;
  },
    agregar_comunas(cid) {
      this.comunas_buscadas = '';
      if (!this.comunas_selected.includes(cid))
        this.comunas_selected.push(cid);
      else
        this.comunas_selected.splice(this.comunas_selected.indexOf(cid), 1);
    },
    openCalendar(event) {
      // Abre el componente MultiDatePicker (se puede ajustar la posición si se desea)
      this.$refs.datePicker.open();
    },
    timeConvert2(n) {
      const hours = Math.floor(n / 60);
      const minutes = Math.round(n % 60);
      return `${hours} h ${minutes} m.`;
    },
    modificar_anticipacion() {
      axios.post(window.hostname + '/api/admin/modificar_anticipacion', {
        profesional_id: this.profesional.id,
        anticipacion: this.anticipacion
      })
      .then(response => {
        this.initialize_(this.$route.params.id);
        this.initialize();
      })
      .catch((e) => console.log("Error al cargar..."));
    },
    cambiarPuedeCategorias() {
      let ret = [];
      for (let i = 0; i < this.categorias.length; i++) {
        if (
          this.profesional.categorias.some(x => x === this.categorias[i].id) !==
          this.editando_categorias.filter(x => x.id === this.categorias[i].id)[0].puede
        ) {
          ret.push({
            id: this.categorias[i].id,
            puede: this.editando_categorias.filter(x => x.id === this.categorias[i].id)[0].puede
          });
        }
      }
      axios.post(window.hostname + '/api/admin/modificar_categorias_pro', {
        id: this.profesional.id,
        categorias: ret
      })
      .then(response => {
        this.initialize_(this.$route.params.id);
      })
      .catch(e => {
        console.log("Error al cargar...");
      });
    },
    eliminar_horario(hid) {
      axios.post(window.hostname + '/api/admin/eliminar_horario', { horario_id: hid })
      .then(() => {
        alert("Horario eliminado");
        this.$router.push({ path: '/Profesional/' + this.$route.params.profesional_id });
      })
      .catch((e) => console.log("Error al cargar..."));
    },
    eliminar_profesional(hid) {
      axios.post(window.hostname + '/api/admin/eliminar_profesional', { profesional_id: hid })
      .then(response => {
        this.initialize_(this.$route.params.id);
      })
      .catch(e => {
        console.log("Error al cargar...");
      });
    },
    timeConvert(n) {
      const hours = Math.floor(n / 60);
      const minutes = Math.round(n % 60);
      return `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
    },
    initialize_(pid) {
  // Se verifica que el parámetro ID sea un número o string
  if (typeof(this.$route.params.id) === 'number' || typeof(this.$route.params.id) === 'string') {
    this.loaded = false;
    axios.post(window.hostname + '/api/admin/init_modificar_horario_pro', { id: pid })
      .then((response) => {
        this.loaded = true;
        const horario = response.data.horario;

        // Campos generales
        this.periodo = horario.intervalo;
        this.agregando_horario.inicio = horario.inicio;
        this.agregando_horario.termino = horario.termino;
        this.anticipacion = horario.anticipacion;
        this.prioridad = horario.prioridad;
        this.recargo_porcentaje = horario.recargo_porcentaje;
        this.recargo_suma = horario.recargo_suma;
        this.comunas_selected = horario.comunas;
        this.genera_bloqueo = horario.genera_bloqueo;

        // Campos "Solo se puede agendar hasta (días antes y hora):"
        this.anticipacion_fija_minima_dias = horario.anticipacion_fija_minima_dias;
        this.anticipacion_fija_minima_hora = horario.anticipacion_fija_minima_hora;

        // Campos "Solo se puede agendar desde (días antes y hora):"
        this.disponible_desde_dias = horario.disponible_desde_dias;
        this.disponible_desde_hora = horario.disponible_desde_hora;

        // Manejo de fechas de vigencia e inicio:
        if (horario.fecha_vigencia) {
          this.fecha_termino_dt = new Date(horario.fecha_vigencia).toISOString().split('T')[0];
          this.fecha_termino_checkbox = true;
        } else {
          this.fecha_termino_checkbox = false;
        }
        if (horario.fecha_inicio) {
          this.fecha_inicio_dt = new Date(horario.fecha_inicio).toISOString().split('T')[0];
          this.fecha_inicio_checkbox = true;
        } else {
          this.fecha_inicio_checkbox = false;
        }

        // Sección de días (repetición):
        // Se convierte el string de 7 caracteres (por ejemplo, "1010101") en un array de objetos
        // [{ value: 1 }, { value: 0 }, ...] para que los checkboxes funcionen correctamente.
        if (horario.repeticion && horario.repeticion.length === 7) {
          this.agregando_horario.repeticion = horario.repeticion
            .split('')
            .map(ch => ({ value: parseInt(ch) }));
        } else {
          // Si no viene correctamente, se reinicializa en 0
          this.agregando_horario.repeticion = [
            { value: 0 }, { value: 0 }, { value: 0 },
            { value: 0 }, { value: 0 }, { value: 0 },
            { value: 0 }
          ];
        }

        // Sección para categorías:
        // Se espera que "horario.categorias" sea un array de IDs.
        // Se genera el array "editando_categorias" usando la lista completa (this.categorias)
        // y marcando aquellas que están presentes en el horario.
        this.editando_categorias = this.categorias.map(cat => ({
          id: cat.id,
          nombre: cat.nombre,
          puede: (horario.categorias && horario.categorias.indexOf(cat.id) !== -1) ? 1 : 0
        }));

        // Sección para fechas específicas:
        // Si el horario tiene la opción activada, se asignan las fechas específicas (en formato "YYYY-MM-DD")
        if (horario.solo_fechas_especificas) {
          this.solo_fechas_especificas = true;
          this.chosenDates = horario.fechas_especificas || [];
        } else {
          this.solo_fechas_especificas = false;
        }
      })
      .catch(e => {
        this.loaded = true;
        console.log("Error al cargar...", e);
      });
  }
},
    agregar_horario(agenda_id, inicio, termino, repeticion) {
      let error_msg = '';
      if (agenda_id == 0)
        error_msg = 'Por favor, ingrese una agenda. ';
      if (inicio === -1)
        error_msg += 'Por favor, ingrese una hora de inicio. ';
      if (termino === -1)
        error_msg += 'Por favor, ingrese una hora de término. ';
      if (inicio > termino)
        error_msg += 'La hora de inicio no puede ser mayor a la de término. ';
  
      let x = '';
      for (let i = 0; i < repeticion.length; i++) {
        x += repeticion[i].value.toString();
      }
      if (x === '0000000')
        error_msg += 'Debes especificar al menos un día. ';
  
      let cat = [];
      for (let j = 0; j < this.editando_categorias.length; j++) {
        if (this.editando_categorias[j].puede) {
          cat.push(this.editando_categorias[j].id);
        }
      }
      if (cat.length === 0)
        error_msg = 'Debes agregar al menos una categoría';
      if (this.comunas_selected.length === 0)
        error_msg = 'Debes agregar al menos una comuna';
  
      if (error_msg === '') {
        let data_ = {
          horario_id: this.$route.params.id,
          inicio: inicio,
          termino: termino,
          repeticion: x,
          categorias: cat,
          comunas: this.comunas_selected,
          anticipacion: this.anticipacion,
          prioridad: this.prioridad,
          recargo_porcentaje: this.recargo_porcentaje,
          recargo_suma: this.recargo_suma,
          intervalo: this.periodo,
          anticipacion_minima: this.anticipacion_minima,
          anticipacion_autoconfirmacion: this.anticipacion_autoconfirmacion,
          anticipacion_fija_minima_dias: this.anticipacion_fija_minima_dias,
          anticipacion_fija_minima_hora: this.anticipacion_fija_minima_hora,
          disponible_desde_dias: this.disponible_desde_dias,
          disponible_desde_hora: this.disponible_desde_hora,
          color: this.color,
          nombre: this.nombre,
          genera_bloqueo: this.genera_bloqueo,
        };
  
        if (this.fecha_inicio_checkbox && this.fecha_inicio_dt !== '')
          data_.fecha_inicio = this.fecha_inicio_dt;
        if (this.fecha_termino_checkbox && this.fecha_termino_dt !== '')
          data_.fecha_termino = this.fecha_termino_dt;
  
        // Agregamos la información de fechas específicas si está activado
        if (this.solo_fechas_especificas) {
          data_.solo_fechas_especificas = true;
          data_.fechas_especificas = this.chosenDates;
        } else {
          data_.solo_fechas_especificas = false;
        }
  
        axios.post(window.hostname + '/api/admin/modificar_horario', data_)
        .then(resp => {
          this.$notify({
            duration: 5000,
            title: 'Horario agregado',
            group: 'foo',
            text: '¡Se ha añadido el horario a la base de datos!',
            type: 'success'
          });
          this.initialize_(this.$route.params.id);
        })
        .catch(e => {
          this.$notify({
            duration: 5000,
            title: 'Error!',
            group: 'foo',
            text: e.response.data.message,
            type: 'error'
          });
        });
      } else {
        console.log("Error:", error_msg);
        this.$notify({
          duration: 5000,
          title: 'Error!',
          group: 'foo',
          text: error_msg,
          type: 'error'
        });
      }
    },
    get_atencion_horario_prestador() {
      const horario_id = this.$route.params.id;
      axios.get(window.hostname + `/api/admin/horarios/${horario_id}/atencion_prestador`)
      .then(resp => {
        this.prestadores = resp.data.prestadores;
        this.prestadores_activos = resp.data.prestadores_activos;
        this.prestadores.forEach(prestador => {
          prestador.activo = this.prestadores_activos.includes(prestador.id);
        });
      })
      .catch(e => {
        this.$notify({
          duration: 5000,
          title: 'Error!',
          text: e.response.data.message,
          type: 'error'
        });
      });
    },
    post_atencion_horario_prestador() {
      const horario_id = this.$route.params.id;
      let prestadores_activos = [];
      this.prestadores.forEach(prestador => {
        if (prestador.activo) {
          prestadores_activos.push(prestador.id);
        }
      });
      this.prestadores_activos = prestadores_activos;
      axios.post(window.hostname + `/api/admin/horarios/${horario_id}/atencion_prestador`, { prestadores_activos })
      .then(resp => {
        this.$notify({
          duration: 5000,
          title: 'Prestadores actualizados',
          text: '¡Se han realizado los cambios con éxito!',
          type: 'success'
        });
        this.get_atencion_horario_prestador();
      })
      .then(() => alert('Se han realizado los cambios con éxito!'))
      .catch(e => {
        this.$notify({
          duration: 5000,
          title: 'Error!',
          text: e.response.data.message,
          type: 'error'
        });
        alert('Ha ocurrido un error al actualizar los prestadores');
      });
    },
  },
  computed: {
    ...mapGetters([
      'comunas',
      'categorias_usadas',
      'prestador',
      'categorias',
      'regiones',
      'prestaciones',
      'tipos',
      'admin_datos',
      'examenes'
    ]),
  },
  watch: {
    comunas_buscadas(val) {
      this.resultado_fuse_comuna = this.fComunas.search(val);
    },
  },
  mounted() {
    this.fComunas = new Fuse(this.comunas, { keys: ['Nombre'] });
    this.get_atencion_horario_prestador();
  }
}
</script>



<style scoped>
.selected-dates {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 5px;
}

.date-badge {
  background-color: #e1f5fe;
  border: 1px solid #81d4fa;
  color: #0277bd;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 0.9em;
}
td{
  text-align: left;
}
.articulo-principal{
 min-height: Calc(100vh - 120px);
  max-width:1390px;



  
  margin: 0 auto;




}
.boxy{
  background-color: white;
  padding: 20px;
  box-shadow: 0 0.125rem 0.25rem rgba(165, 163, 174, 0.3);
  border-radius: 8px;
}
table
{ 
    margin-left: auto;
    margin-right: auto;
}
.column-right{
  background-color: rgb(242, 247, 251);

  padding-top:0px;
  padding-left:275px;
  width:100%;
  

}
.main-container{
  background-color: rgb(242, 247, 251);
  padding: 10px;

  margin-top:10px;

}
@media (max-width: 800px) {
 .column-right{

  padding-left:0px;


}
}
</style>

