 <!-- eslint-disable -->
<!-- MultiDatePicker.vue -->
<template>
  <div 
    class="multi-date-picker"
    v-if="visible"
    :style="calendarStyle"
  >
    <!-- Header with month and year navigation -->
    <div class="mdp-header">
      <button class="mdp-btn" @click="prevMonth">&lt;</button>
      <div class="mdp-header-text">
        {{ monthNames[currentMonth] }} {{ currentYear }}
      </div>
      <button class="mdp-btn" @click="nextMonth">&gt;</button>
    </div>

    <!-- Weekday labels -->
    <div class="mdp-weekdays">
      <span v-for="(day, index) in weekdayShort" :key="index" class="mdp-weekday">
        {{ day }}
      </span>
    </div>

    <!-- Days grid -->
    <div class="mdp-days">
      <!-- Empty slots for days from previous month -->
      <span 
        v-for="(emptyDay, index) in firstDayOffset" 
        :key="'empty-'+index"
        class="mdp-day-empty"
      ></span>
      
      <!-- Actual days of the current month -->
      <span 
        v-for="day in daysInMonth" 
        :key="'day-'+day"
        class="mdp-day"
        :class="{ 'selected': isSelected(day) }"
        @click="selectDay(day)"
      >
        {{ day }}
      </span>
    </div>
    
    <div style="text-align: right;">
      <!-- Instead of modifying a prop, we now call the internal close() method -->
      <button @click="close" class="btn" style="color:white" :style="{'background-color': prestador.admin.assets.colors.primary}">
        Listo
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: "MultiDatePicker",
  props: {
    value: { // Vue 2 uses "value" for v-model
      type: Array,
      default: () => [],
    },
    x: {
      type: Number,
      default: 0,
    },
    y: {
      type: Number,
      default: 0,
    },
    initialYear: {
      type: Number,
      default: () => new Date().getFullYear(),
    },
    initialMonth: {
      type: Number,
      default: () => new Date().getMonth(),
    },
  },
  data() {
    return {
      // Control visibility internally (default: hidden)
      visible: false,
      currentYear: this.initialYear,
      currentMonth: this.initialMonth,
      selectedDates: [...this.value], // Local copy
      monthNames: [
        "Enero", "Febrero", "Marzo", "Abril",
        "Mayo", "Junio", "Julio", "Agosto",
        "Septiembre", "Octubre", "Noviembre", "Diciembre"
      ],
      weekdayShort: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa"],
    };
  },
  computed: {
    ...mapGetters(['prestador']),
    daysInMonth() {
      return new Date(this.currentYear, this.currentMonth + 1, 0).getDate();
    },
    firstDayOffset() {
      const firstDay = new Date(this.currentYear, this.currentMonth, 1).getDay();
      return Array.from({ length: firstDay });
    },
    calendarStyle() {
      return {
        position: 'absolute',
        bottom: this.y + 'px',
        left: this.x + 'px',
        zIndex: 9999,
      };
    },
  },
  watch: {
    value: {
      handler(newVal) {
        // Prevent infinite loop by checking for actual changes
        if (JSON.stringify(this.selectedDates) !== JSON.stringify(newVal)) {
          this.selectedDates = [...newVal];
        }
      },
      deep: true
    },
    selectedDates: {
      handler(newVal) {
        this.$emit('input', [...newVal]);
      },
      deep: true
    }
  },
  methods: {
    // Public method to open the calendar (parent can call via a ref)
    open() {
      this.visible = true;
    },
    // Public method to close the calendar
    close() {
      this.visible = false;
    },
    selectDay(dayNumber) {
      const dateString = this.formatDate(this.currentYear, this.currentMonth, dayNumber);
      
      // Toggle the selected date using an immutable update pattern
      const newDates = this.selectedDates.includes(dateString)
        ? this.selectedDates.filter(date => date !== dateString)
        : [...this.selectedDates, dateString];

      this.selectedDates = newDates;
    },
    isSelected(dayNumber) {
      const dateString = this.formatDate(this.currentYear, this.currentMonth, dayNumber);
      return this.selectedDates.includes(dateString);
    },
    prevMonth() {
      if (this.currentMonth === 0) {
        this.currentMonth = 11;
        this.currentYear--;
      } else {
        this.currentMonth--;
      }
    },
    nextMonth() {
      if (this.currentMonth === 11) {
        this.currentMonth = 0;
        this.currentYear++;
      } else {
        this.currentMonth++;
      }
    },
    formatDate(year, monthIndex, day) {
      const mm = String(monthIndex + 1).padStart(2, "0");
      const dd = String(day).padStart(2, "0");
      return `${year}-${mm}-${dd}`;
    },
  },
};
</script>

<style scoped>
.multi-date-picker {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 6px;
  width: 220px; /* compact width */
  box-shadow: 0 2px 8px rgba(0,0,0,0.15);
  font-family: sans-serif;
  padding: 8px;
  box-sizing: border-box;
}

.mdp-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;
}

.mdp-header-text {
  font-weight: 600;
}

.mdp-btn {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 14px;
  padding: 4px 8px;
}

.mdp-btn:hover {
  background-color: #f3f3f3;
}

.mdp-weekdays, .mdp-days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  text-align: center;
  margin-bottom: 4px;
}

.mdp-weekday {
  font-size: 0.75rem;
  color: #999;
}

.mdp-day,
.mdp-day-empty {
  width: 28px;
  height: 28px;
  line-height: 28px;
  margin: 2px auto;
  cursor: pointer;
  border-radius: 4px;
  font-size: 0.9rem;
}

.mdp-day:hover {
  background-color: #eee;
}

.selected {
  background-color: #2196f3;
  color: #fff;
}
</style>
